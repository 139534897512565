import React from "react";
import { ProductMaterialCard4 } from "../../Components/ProductMaterialCard/ProductMaterialCard";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import "./RentGarage2.scss";
import "../../static/css/style_helper/style_helper.scss";
import { Box, useMediaQuery } from "@mui/material";
function RentGarage2(props) {
  return (
    <div id="RentGarage2">
      <Box sx={{ mb: 5 }}>
        <Section1></Section1>
      </Box>
    </div>
  );
}

export default RentGarage2;

const Section1 = () => {
  const cardObject = [
    {
      id: 1,
      img: require("../../static/img/carriage-house-with-cars-1-1586098774.jpg"),
      title: "GARAGE 1",
      link: "#",
      location: "14, KANTON AARGAU, DENSBUREN, 5026",
    },
    {
      id: 2,
      img: require("../../static/img/large_B3-EN112_Garage_IM_20190717123019.jpg"),
      title: "GARAGE 2",
      link: "#",
      location: "14, KANTON AARGAU, DENSBUREN, 5026",
    },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <section id="section1">
      <Box sx={{ mb: 2, mt: matches ? 0 : 2 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{ width: matches ? "50%" : "70%" }}
        >
          Garage Result
        </h3>
      </Box>

      <Box>
        <Box className="filter_wrapper">
          <h2
            className="title_line section_title_lg d_inline_block"
            style={{ marginTop: 0 }}
          >
            Garages
          </h2>
        </Box>

        <Box
          className="form_search"
          sx={{ mb: 5, display: "flex", justifyContent: matches? "flex-start":"center" }}
        >
          <div className="form_group" style={{ display: "inline-block" }}>
            <select defaultValue="s" className="select_control_v2">
              <option value="s" disabled>
                Sorting
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDown className="form_icon"></KeyboardArrowDown>
          </div>
        </Box>

        <Box>
          <ProductMaterialCard4 cardObject={cardObject}></ProductMaterialCard4>
        </Box>
      </Box>
    </section>
  );
};
