import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "../SellCar/SellCar.scss";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PaymentIcon from "@mui/icons-material/Payment";
import { Link } from "react-router-dom";
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import { importExcelFileHttpRequest, importCSVFileHttpRequest, downloadCsvHttpRequest, getAllPackagesHttpRequest, getPackagesListHttpRequest, deletePackagesListHttpRequest, activateInactiveVehicleHttpRequest } from "../../static/http-content";
import moment from "moment";
import { toast } from "react-toastify";
import { useIntl } from 'react-intl';
import csv from '../../assets/templetecsv/vehicle.csv';
import xlsx from '../../assets/templeteexcel/vehicle.xlsx';
import vehicleIdImg from '../../assets/images/Vehicle ID.png';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: "white",
    background: "transparent",
    boxShadow: 0,
}));

function SellCar(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const [isDisable, setDisable] = useState(false);
    const matches = useMediaQuery("(min-width:600px)");
    const [showSideBar, setShowSideBar] = useState(false);
    const [packageData, setPackageData] = useState([]);
    const [packageDetail, setPackageDetail] = useState(null);
    const [searchData, setSearchData] = useState({
        vehicle_id: null,
        month: 0,
        year: 0
    })
    const intl = useIntl();

    useEffect(() => {
        getAllPackagesHttpRequest
            .get(`/${id}/`, {
                headers: {
                    Authorization: `jwt ${localStorage.getItem("token")}`,
                }
            })
            .then((res) => {
                setPackageDetail(res?.data?.data);
            })
            .catch((err) => {
                let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
                toast.update(
                    toast_id,
                    {
                        render: err?.response?.data?.errors?.detail,
                        type: 'error',
                        isLoading: false,
                        closeOnClick: true,
                        autoClose: 5000,
                        pauseOnHover: true,
                    }
                )
            });
        getPackagesListHttpRequest
            .get("/?package_subscription__package=" + id, {
                headers: {
                    Authorization: `jwt ${localStorage.getItem("token")}`,
                }
            })
            .then((res) => {
                setPackageData(res.data.data);
                if (res?.data?.data.length === packageDetail?.number_of_vehicle) {
                    setDisable(true);
                }
            })
            .catch((err) => console.log(err.response));
    }, []);
    return (
        <div
            id="AdvertisementHistory"
            style={{
                marginLeft: matches ? "260px" : "0px",
            }}
        >
            <Box sx={{ my: 3 }}>
                <h3
                    className="section_title_cyan max_size_cyan"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                    }}
                >
                    {matches ? null : (
                        <MenuIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowSideBar(true)}
                        />
                    )}
                    Sell Car
                </h3>
            </Box>
            <Box
                display="flex"
                sx={{ pb: 8, background: "var(--auto-text-f1)" }}
                className="full_content_wrapper"
            >
                <UserPanelAside
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                />
                <Box flexGrow="1" sx={{ p: matches ? 4 : 1, maxWidth: "80%" }}>
                    <Box
                        id="historyBox"
                        sx={{
                            boxShadow: 3,
                            borderRadius: 3,
                            background: "rgba(71, 72, 74, 0.9)",
                            marginBottom: 2
                        }}
                    >
                        <BoxHead searchData={searchData} setSearchData={setSearchData} isDisable={isDisable} setDisable={setDisable} packageDetail={packageDetail} setPackageDetail={setPackageDetail} packageData={packageData} setPackageData={setPackageData}></BoxHead>
                    </Box>
                    {/* <BoxButton isDisable={isDisable} setDisable={setDisable} packageDetail={packageDetail} setPackageDetail={setPackageDetail} packageData={packageData} setPackageData={setPackageData}></BoxButton> */}
                    <Box
                        id="historyBox"
                        sx={{
                            boxShadow: 3,
                            borderRadius: 3,
                            background: "var(--auto-text-white)",
                        }}
                    >
                        <BoxBody packageData={packageData} setPackageData={setPackageData}></BoxBody>
                    </Box>
                </Box>
                <Box flexGrow="1" sx={{ py: matches ? 4 : 1, maxWidth: "20%" }}>
                    <Box
                        id="historyBox"
                        sx={{
                            boxShadow: 3,
                            borderRadius: 3,
                            background: "rgba(71, 72, 74, 0.9)",
                        }}
                    >
                        <img
                            src={vehicleIdImg}
                            alt="registration_image"
                            style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "20px",
                                objectFit: "cover",
                            }}
                        />
                        <Box style={{ textAlign: "center" }}>
                            <div style={{ color: "white" }}>Don't have ID Information ?</div>
                            <Link to={`/vehicle_type?id=${id}`} style={{ color: "#00aeef", textDecoration: "none" }}>
                                Enter info manually
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default SellCar;

const BoxHead = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const inputRef1 = useRef(null);
    const inputRef = useRef(null);
    const intl = useIntl();
    const { searchData } = props;
    const matches = useMediaQuery("(min-width:600px)");
    const [Import, setImport] = useState(0);
    const [Export, setExport] = useState(0);
    const handleChange = (event) => {
        setImport(event.target.value);
        if (event.target.value == 10) {
            downloadCsvHttpRequest
                .get(`/?_export=yes&export_format=csv&package_subscription__package=${id}`, {
                    headers: {
                        Authorization: `jwt ${localStorage.getItem("token")}`,
                    },
                    responseType: 'blob'
                })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "vehicle.csv");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => console.log(err.response));
        } else if (event.target.value == 20) {
            downloadCsvHttpRequest
                .get(`/?_export=yes&export_format=xlsx&package_subscription__package=${id}`, {
                    headers: {
                        Authorization: `jwt ${localStorage.getItem("token")}`,
                    },
                    responseType: 'blob'
                })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "vehicle.xlsx");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => console.log(err.response));
        }
    };
    const handleChanges = (event) => {
        setExport(event.target.value);
        if (event.target.value == 10) {
            inputRef.current?.click();
        } else if (event.target.value == 20) {
            inputRef1.current?.click();
        }
    };
    const changeCsvHandler = () => {
        let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
        let formData = new FormData();
        formData.append("file", inputRef.current?.files[0]);
        formData.append("package", id);
        importCSVFileHttpRequest
            .post("/", formData, {
                headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                getAllPackagesHttpRequest
                    .get(`/${id}/`, {
                        headers: {
                            Authorization: `jwt ${localStorage.getItem("token")}`,
                        }
                    })
                    .then((res) => {
                        props?.setPackageDetail(res?.data?.data);
                        getPackagesListHttpRequest
                            .get("/?package_subscription__package=" + id, {
                                headers: {
                                    Authorization: `jwt ${localStorage.getItem("token")}`,
                                }
                            })
                            .then((res) => {
                                props?.setPackageData(res.data.data);
                                if (res?.data?.data.length === props?.packageDetail?.number_of_vehicle) {
                                    props?.setDisable(true);
                                }
                            })
                            .catch((err) => console.log(err.response));
                    })
                    .catch((err) => console.log(err.response));
                toast.update(
                    toast_id,
                    {
                        render: "CSV Import Successfully",
                        type: 'success',
                        isLoading: false,
                        closeOnClick: true,
                        autoClose: 5000,
                        pauseOnHover: true,
                    }
                )
            })
            .catch((err) => console.log(err.response));
    };
    const changeExcelHandler = () => {
        let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
        let formData = new FormData();
        formData.append("file", inputRef1.current?.files[0]);
        formData.append("package", id);
        importExcelFileHttpRequest
            .get("/", formData, {
                headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                getAllPackagesHttpRequest
                    .get(`/${id}/`, {
                        headers: {
                            Authorization: `jwt ${localStorage.getItem("token")}`,
                        }
                    })
                    .then((res) => {
                        props?.setPackageDetail(res?.data?.data);
                        getPackagesListHttpRequest
                            .get("/?package_subscription__package=" + id, {
                                headers: {
                                    Authorization: `jwt ${localStorage.getItem("token")}`,
                                }
                            })
                            .then((res) => {
                                props?.setPackageData(res.data.data);
                                if (res?.data?.data.length === props?.packageDetail?.number_of_vehicle) {
                                    props?.setDisable(true);
                                }
                            })
                            .catch((err) => console.log(err.response));
                    })
                    .catch((err) => console.log(err.response));
                toast.update(
                    toast_id,
                    {
                        render: "Excel Import Successfully",
                        type: 'success',
                        isLoading: false,
                        closeOnClick: true,
                        autoClose: 5000,
                        pauseOnHover: true,
                    }
                )
            })
            .catch((err) => console.log(err.response));
    };
    const handleChangeMonth = (event) => {
        props.setSearchData({
            ...props.searchData,
            month: +event.target.value,
        });
    };
    const handleChangeYear = (event) => {
        props.setSearchData({
            ...props.searchData,
            year: +event.target.value,
        });
    };
    const submit = () => {
        let url = `/?package_subscription__package=${id}`;
        if (searchData) {
            if (searchData.vehicle_id) {
                url += `&search=${searchData.vehicle_id}`;
            }
            if (searchData.month) {
                url += `&registration_date_month=${searchData.month}`;
            }
            if (searchData.year) {
                url += `&registration_date_year=${searchData.year}`;
            }
            getPackagesListHttpRequest
                .get(url, {
                    headers: {
                        Authorization: `jwt ${localStorage.getItem("token")}`,
                    }
                })
                .then((res) => {
                    props.setPackageData(res.data.data);
                })
                .catch((err) => console.log(err.response));
        }
    };

    return (
        <Box id="BoxHead">
            <form action="" className="responsiveBoxHead form_history_search">
                <Box className="form_group" sx={{ mr: 1 }}>
                    <div style={{ color: "white", fontWeight: "500" }} className="form_label">Vehicle ID:</div>
                    <TextField
                        id="vehicle_id"
                        variant="outlined"
                        inputProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                        style={{ background: "white", borderRadius: "20px" }}
                        onChange={(e) => {
                            props.setSearchData({
                                ...props.searchData,
                                vehicle_id: e.target.value,
                            });
                        }}
                    />
                </Box>
                <Box sx={{ ml: 5, mr: 1, display: "flex", alignItems: "center", justifyContent: "end" }}>
                    <div style={{ color: "white", fontWeight: "500" }} className="form_label">Date:</div>&nbsp;&nbsp;
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{ background: "white", borderRadius: "20px" }}
                            SelectDisplayProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                            className="form_group"
                            label="Month"
                            value={searchData.month}
                            onChange={handleChangeMonth}
                        >
                            <MenuItem value={0} disabled>Month</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mr: 1, justifyContent: "end" }}>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{ background: "white", borderRadius: "20px" }}
                            SelectDisplayProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                            className="form_group"
                            defaultValue={0}
                            label="Year"
                            onChange={handleChangeYear}
                        >
                            <MenuItem value={0} disabled>Year</MenuItem>
                            <MenuItem value={2018}>2018</MenuItem>
                            <MenuItem value={2019}>2019</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2025}>2025</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mr: 1, justifyContent: "end" }}>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{ background: "white", borderRadius: "20px" }}
                            SelectDisplayProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                            value={Import}
                            className="form_group"
                            label="Age"
                            onChange={handleChange}
                        >
                            <MenuItem value={0}>Export</MenuItem>
                            <MenuItem value={10}>CSV</MenuItem>
                            <MenuItem value={20}>Excel</MenuItem>
                            <MenuItem value={30}><a style={{ textDecoration: "none", color: "black" }} href={csv} target="_blank" download="vehicle">Templete</a></MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{ background: "white", borderRadius: "20px" }}
                            SelectDisplayProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                            value={Export}
                            className="form_group"
                            label="Export"
                            onChange={handleChanges}
                        >
                            <MenuItem value={0}>Import</MenuItem>
                            <MenuItem value={10}>CSV</MenuItem>
                            <MenuItem value={20}>Excel</MenuItem>
                            <MenuItem value={30}><a style={{ textDecoration: "none", color: "black" }} href={xlsx} target="_blank" download="vehicle">Templete</a></MenuItem>
                        </Select>
                    </FormControl>
                    <input
                        ref={inputRef}
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeCsvHandler}
                        style={{ display: "none", margin: "10px auto" }}
                    />
                    <input
                        ref={inputRef1}
                        type="file"
                        name="file"
                        accept=".xlsx"
                        onChange={changeExcelHandler}
                        style={{ display: "none", margin: "10px auto" }}
                    />
                </Box>
                <Box className="form_group" sx={{ justifyContent: "end" }}>
                    <Button onClick={submit}
                        className="btn_cyan_style"
                        sx={{ py: 0, px: 3, width: matches ? "auto" : "100%" }}
                    >
                        CHECK
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

const BoxBody = (props) => {
    const matches = useMediaQuery("(min-width:600px)");
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");

    const inactive = (vehicle) => {
        activateInactiveVehicleHttpRequest
            .post("/", { "vehicle_id": vehicle }, {
                headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                getPackagesListHttpRequest
                    .get("/?package_subscription_package=" + id, {
                        headers: {
                            Authorization: `jwt ${localStorage.getItem("token")}`,
                        }
                    })
                    .then((res) => {
                        props.setPackageData(res.data.data);
                    })
                    .catch((err) => console.log(err.response));
            })
            .catch((err) => console.log(err.response));
    }

    const submit = (ids) => {
        deletePackagesListHttpRequest
            .delete(`/${ids}`)
            .then((res) => {
                getPackagesListHttpRequest
                    .get("/?package_subscription_package=" + id, {
                        headers: {
                            Authorization: `jwt ${localStorage.getItem("token")}`,
                        }
                    })
                    .then((res) => {
                        props.setPackageData(res.data.data);
                    })
                    .catch((err) => console.log(err.response));
            })
            .catch((err) => console.log(err.response));
    }
    return (
        <>
            {matches ? (
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="history_table"
                        className="history_table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Kind</TableCell>
                                <TableCell align="center">
                                    Body
                                </TableCell>
                                <TableCell align="center">
                                    Rating
                                </TableCell>
                                <TableCell align="center">
                                    Seats
                                </TableCell>
                                <TableCell align="center">
                                    Gear Type
                                </TableCell>
                                <TableCell align="center">
                                    Fuel
                                </TableCell>
                                <TableCell align="center">
                                    Drive
                                </TableCell>
                                <TableCell align="right">ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.packageData.map((row) => {
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        {/* <TableCell className="row_id" component="td" scope="row">
                                            {row.id}
                                        </TableCell> */}
                                        <TableCell component="td" scope="row">
                                            <Box
                                                component="img"
                                                alt="image"
                                                className="history_image"
                                                src={row?.images[0]?.image}
                                                sx={{ boxShadow: 3 }}
                                            ></Box>
                                        </TableCell>
                                        <TableCell
                                            className="text_nowrap"
                                            align="center"
                                            sx={{ fontWeight: "bold" }}
                                            component="td"
                                        >
                                            {row.type}
                                        </TableCell>
                                        <TableCell
                                            className="text_nowrap"
                                            align="center"
                                            sx={{ fontWeight: "bold" }}
                                            component="td"
                                        >
                                            {row.safety_rating}
                                        </TableCell>
                                        <TableCell
                                            className="text_nowrap"
                                            align="center"
                                            sx={{ fontWeight: "bold" }}
                                            component="td"
                                        >
                                            {row.no_of_seats}
                                        </TableCell>
                                        <TableCell
                                            className="text_nowrap"
                                            align="center"
                                            sx={{ fontWeight: "bold" }}
                                            component="td"
                                        >
                                            {row.transmission}
                                        </TableCell>
                                        <TableCell
                                            className="text_nowrap"
                                            align="center"
                                            sx={{ fontWeight: "bold" }}
                                            component="td"
                                        >
                                            {row.fuel}
                                        </TableCell>
                                        <TableCell
                                            className="text_nowrap"
                                            align="center"
                                            sx={{ fontWeight: "bold" }}
                                            component="td"
                                        >
                                            {row.drive}
                                        </TableCell>
                                        <TableCell align="center" component="td">
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <IconButton
                                                    type="button"
                                                    className="btn_cyan_style"
                                                    aria-label="delete"
                                                    sx={{ p: 1, mb: 1, borderRadius: "10px!important" }}
                                                    size="large"
                                                    onClick={() => submit(row.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>

                                                <IconButton
                                                    className="btn_cyan_style"
                                                    aria-label="edit"
                                                    sx={{ p: 1, mb: 1, borderRadius: "10px!important" }}
                                                    size="large"
                                                    component={Link}
                                                    to={`/sell_car2?id=${id}&carId=${row.id}`}
                                                >
                                                    <EditIcon />
                                                </IconButton>

                                                {row.is_active ? null : <IconButton
                                                    type="button"
                                                    className="btn_cyan_style"
                                                    aria-label="editattributesicon"
                                                    sx={{ p: 1, borderRadius: "10px!important" }}
                                                    size="large"
                                                    onClick={() => inactive(row?.images[0]?.vehicle)}
                                                >
                                                    <EditAttributesIcon />
                                                </IconButton>}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : null
                // (
                //     <List
                //         sx={{ width: "100%", padding: "0px" }}
                //         component="nav"
                //         aria-labelledby="nested-list-subheader"
                //     >
                //         {rows.map((row) => {
                //             let status_color;
                //             switch (row.status.toLowerCase()) {
                //                 case "active":
                //                     status_color = "success";
                //                     break;
                //                 case "pending":
                //                     status_color = "error";
                //                     break;
                //                 default:
                //                     status_color = "black";
                //             }
                //             return (
                //                 <div
                //                     style={{
                //                         borderBottom: "3px solid #00aeef",
                //                         paddingBottom: "1rem",
                //                         marginTop: "1rem",
                //                     }}
                //                 >
                //                     <ListItem>
                //                         <ListItemIcon style={{ fontWeight: "bold" }}>
                //                             # {row.id}
                //                         </ListItemIcon>
                //                         <ListItemText
                //                             primary={
                //                                 <Box
                //                                     sx={{
                //                                         display: "flex",
                //                                         justifyContent: "flex-end",
                //                                         alignItems: "center",
                //                                         width: "100%",
                //                                     }}
                //                                 >
                //                                     <IconButton
                //                                         className="btn_cyan_style"
                //                                         aria-label="delete"
                //                                         sx={{ borderRadius: "10px!important" }}
                //                                         size="large"
                //                                     >
                //                                         <DeleteIcon />
                //                                     </IconButton>

                //                                     <IconButton
                //                                         className="btn_cyan_style"
                //                                         aria-label="delete"
                //                                         sx={{ borderRadius: "10px!important", ml: 1 }}
                //                                         size="large"
                //                                     >
                //                                         <EditIcon />
                //                                     </IconButton>
                //                                 </Box>
                //                             }
                //                         />
                //                     </ListItem>
                //                     <Collapse in={true} timeout="auto" unmountOnExit>
                //                         <Box
                //                             component="img"
                //                             alt="image"
                //                             className="history_image"
                //                             src={row.image}
                //                             sx={{
                //                                 boxShadow: 3,
                //                                 width: "inherit",
                //                                 borderRadius: "10px",
                //                             }}
                //                         ></Box>
                //                         <Button
                //                             className="btn_cyan_style"
                //                             sx={{ width: "100%", marginTop: "0.5rem" }}
                //                             startIcon={<PaymentIcon />}
                //                         >
                //                             Pay Now
                //                         </Button>
                //                         <Box
                //                             sx={{
                //                                 width: "inherit",
                //                                 border: "1px solid #aaa",
                //                                 borderRadius: "10px",
                //                                 padding: "0.5rem",
                //                                 display: "flex",
                //                                 justifyContent: "space-between",
                //                                 marginTop: "0.5rem",
                //                             }}
                //                         >
                //                             <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                //                                 Active Date
                //                             </span>
                //                             <span>{row.active_date}</span>
                //                         </Box>
                //                         <Box
                //                             sx={{
                //                                 width: "inherit",
                //                                 border: "1px solid #aaa",
                //                                 borderRadius: "10px",
                //                                 padding: "0.5rem",
                //                                 display: "flex",
                //                                 justifyContent: "space-between",
                //                                 marginTop: "0.5rem",
                //                             }}
                //                         >
                //                             <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                //                                 Expire Date
                //                             </span>
                //                             <span>{row.expire_date}</span>
                //                         </Box>
                //                         <Box
                //                             sx={{
                //                                 width: "inherit",
                //                                 border: "1px solid #aaa",
                //                                 borderRadius: "10px",
                //                                 padding: "0.5rem",
                //                                 display: "flex",
                //                                 justifyContent: "space-between",
                //                                 marginTop: "0.5rem",
                //                             }}
                //                         >
                //                             <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                //                                 Status
                //                             </span>
                //                             <Button
                //                                 component="span"
                //                                 sx={{ textTransform: "capitalize", p: 0 }}
                //                                 color={status_color}
                //                             >
                //                                 {row.status}
                //                             </Button>
                //                         </Box>
                //                     </Collapse>
                //                 </div>
                //             );
                //         })}
                //     </List>
                // )
            }
        </>
    );
};

