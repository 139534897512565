import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./CarAdvertisement2.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.css";
import { Link } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import { createLocalAdsHttpRequest } from "../../static/http-content";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function CarAdvertisement2(props) {
  const intl = useIntl();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [localAds, setLocalAds] = useState({
    title: null,
    ad_link: null,
    price: null,
    package_id: id,
  });
  const [image, setImages] = useState(null);

  useEffect(() => {}, []);

  const submit = () => {
    debugger;
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    formData.append("title", localAds?.title);
    formData.append("ad_link", localAds?.ad_link);
    formData.append("price", localAds?.price);
    formData.append("package_id", localAds?.package_id);
    if (image) {
      image.forEach((element) => {
        formData.append("image", element);
      });
    }
    createLocalAdsHttpRequest
      .post("/", formData, {
        headers: {
          Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
        },
      })
      .then((res) => {
        toast.update(toast_id, {
          render: "Local Advertisement Created Successfully",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <div id="CarAdvertisement2">
      <Box sx={{ my: 3 }}>
        <h3 className="section_title_cyan max_size_cyan">Add Advertisement</h3>
      </Box>
      <Box display="flex" sx={{ pb: 8 }} className="full_content_wrapper">
        {/* <UserPanelAside></UserPanelAside> */}
        <Box flexGrow="1" sx={{ p: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <Box sx={{ p: 2 }}>
                <FormBox1
                  localAds={localAds}
                  setLocalAds={setLocalAds}
                ></FormBox1>
                <FormBox2 image={image} setImages={setImages}></FormBox2>
                <FormSubmit submit={submit} />
              </Box>
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default CarAdvertisement2;

const FormBox1 = (props) => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Personal Details</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Title:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Title..."
                  value={props?.localAds?.title}
                  onChange={(e) => {
                    props.setLocalAds({
                      ...props.localAds,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Link:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Link..."
                  value={props?.localAds?.ad_link}
                  onChange={(e) => {
                    props.setLocalAds({
                      ...props.localAds,
                      ad_link: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Price:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Price..."
                  value={props?.localAds?.price}
                  onChange={(e) => {
                    props.setLocalAds({
                      ...props.localAds,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, textAlign: "center" }}>
              <h3
                className="btn_cyan_style max_size_cyan"
                style={{ display: "inline-block", padding: "4px 12px" }}
              >
                Please upload image of this particular height and width
                (798x360).
              </h3>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = (props) => {
  const handleUploader = (files) => {
    if (files.length > 0) {
      props.setImages(files);
    }
  };
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Vehicle Data</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item
            sx={{
              boxShadow: 0,
            }}
            className="Upload_picture_dropzone_wrapper"
          >
            <label htmlFor="" className="form_label">
              Image:{" "}
            </label>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={handleUploader}
              filesLimit={20}
              showAlerts={false}
            />
            {/* {props.previewImage.map((img) =>
              <div class="img-wrap">
                <Button
                  id="clear"
                  type="button"
                  sx={{ textTransform: "capitalize", p: 0 }}
                  color="error"
                  onClick={() => removeImg(img.id)}
                >
                  <DeleteIcon />
                </Button>
                <img id="output" src={img.image} style={{
                  width: "120px",
                  height: "100px",
                  borderRadius: "20px",
                  objectFit: "cover",
                  margin: "5px"
                }} />
              </div>
            )}  */}
          </Item>
        </Grid>
      </Grid>
    </Box>
    // <Box>
    //   <Box textAlign="center">
    //     <h2 className="title_line">Vehicle Data</h2>
    //   </Box>{" "}
    //   <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
    //     <Grid item xs={12}>
    //       <Item sx={{ boxShadow: 0 }}>
    //         <Box sx={{ mb: 0 }}>
    //           <div className="form_group">
    //             <label htmlFor="" className="form_label">
    //               Image:
    //             </label>
    //             <input
    //               style={{ display: "none" }}
    //               type="file"
    //               className="form_control"
    //               placeholder="Year of Registration of Vehicle..."
    //               id="Vehicle_rc_input"
    //               onChange={handleUploader}
    //             />
    //             <div className="upload_wrapper">
    //               <PictureUploader></PictureUploader>
    //             </div>
    //           </div>
    //         </Box>
    //       </Item>
    //     </Grid>
    //   </Grid>
    // </Box>
  );
};

const FormSubmit = (props) => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button
        type="button"
        sx={{ px: 6 }}
        className="btn_cyan_style"
        onClick={props.submit}
      >
        SUBMIT
      </Button>
      <Button
        sx={{ px: 6, ml: 2 }}
        className="btn_cyan_style"
        component={Link}
        to={`/advertisement_history`}
      >
        BACK TO LIST
      </Button>
    </Box>
  );
};
