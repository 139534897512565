import React, { useState } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "../AdvertisementHistory/AdvertisementHistory.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.css";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PaymentIcon from "@mui/icons-material/Payment";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function BikeSellHistory(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="AdvertisementHistory"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          Bike Sell History
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 4 : 1, maxWidth: "100%" }}>
          <Box
            id="historyBox"
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              background: "var(--auto-text-white)",
            }}
          >
            <BoxHead></BoxHead>
            <BoxBody></BoxBody>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default BikeSellHistory;

const BoxHead = () => {
  const btn_action_object = [
    {
      id: 1,
      name: "CSV",
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 2,
      name: "Excel",
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 3,
      name: "Advertisement Values",
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 4,
      name: "Import CSV",
      icon: <CloudDownloadIcon></CloudDownloadIcon>,
      link: "",
    },
    {
      id: 5,
      name: "Import Excel",
      icon: <CloudDownloadIcon></CloudDownloadIcon>,
      link: "",
    },
    {
      id: 6,
      name: "Download History",
      icon: "",
      link: "",
    },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box id="BoxHead">
      <form action="" className="responsiveBoxHead form_history_search">
        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">From Date:</div>
          <HistoryDate></HistoryDate>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">To Date:</div>
          <HistoryDate></HistoryDate>
        </Box>
        <Box className="form_group" sx={{ justifyContent: "flex-end" }}>
          <Button
            className="btn_cyan_style"
            sx={{ py: 0, px: 3, width: matches ? "auto" : "100%" }}
          >
            SUBMIT
          </Button>
        </Box>
      </form>
      <Box className="btn_download_wrapper" sx={{ my: 3 }}>
        {btn_action_object.map((v) => (
          <Button
            key={v.id}
            className="btn_cyan_style"
            sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
          >
            {v.icon}
            {v.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const HistoryDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      className="datePicker"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

function createData(id, image, active_date, expire_date, status, action) {
  return { id, image, active_date, expire_date, status, action };
}

const rows = [
  createData(
    1,
    require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Active",
    2
  ),
  createData(
    2,
    require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Pending",
    2
  ),
  createData(
    3,
    require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Pending",
    2
  ),
  createData(
    4,
    require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Pending",
    2
  ),
];

const BoxBody = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {matches ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="history_table"
            className="history_table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontStyle: "italic" }}>#</TableCell>
                <TableCell align="center">IMAGE</TableCell>
                <TableCell align="center">
                  ACTIVE <br /> DATE
                </TableCell>
                <TableCell align="center">
                  EXPIRE <br /> DATE
                </TableCell>
                <TableCell align="right">STATUS</TableCell>
                <TableCell align="right">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                let status_color;
                switch (row.status.toLowerCase()) {
                  case "active":
                    status_color = "success";
                    break;
                  case "pending":
                    status_color = "error";
                    break;
                  default:
                    status_color = "black";
                }
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="row_id" component="td" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <Box
                        component="img"
                        alt="image"
                        className="history_image"
                        src={row.image}
                        sx={{ boxShadow: 3 }}
                      ></Box>
                    </TableCell>
                    <TableCell
                      className="text_nowrap"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                      component="td"
                    >
                      {row.active_date}
                    </TableCell>
                    <TableCell
                      className="text_nowrap"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                      component="td"
                    >
                      {row.expire_date}
                    </TableCell>
                    <TableCell
                      component="td"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      <Button
                        component="span"
                        sx={{ textTransform: "capitalize", p: 0 }}
                        color={status_color}
                      >
                        {row.status}
                      </Button>
                    </TableCell>
                    <TableCell align="center" component="td">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          className="btn_cyan_style"
                          sx={{ fontSize: "13px!important", py: 0, mb: 1 }}
                        >
                          Pay Now
                        </Button>
                        <IconButton
                          className="btn_cyan_style"
                          aria-label="delete"
                          sx={{ p: 1, mb: 1, borderRadius: "10px!important" }}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>

                        <IconButton
                          className="btn_cyan_style"
                          aria-label="delete"
                          sx={{ p: 1, borderRadius: "10px!important" }}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List
          sx={{ width: "100%", padding: "0px" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {rows.map((row) => {
            let status_color;
            switch (row.status.toLowerCase()) {
              case "active":
                status_color = "success";
                break;
              case "pending":
                status_color = "error";
                break;
              default:
                status_color = "black";
            }
            return (
              <div
                style={{
                  borderBottom: "3px solid #00aeef",
                  paddingBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <ListItem>
                  <ListItemIcon style={{ fontWeight: "bold" }}>
                    # {row.id}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <IconButton
                          className="btn_cyan_style"
                          aria-label="delete"
                          sx={{ borderRadius: "10px!important" }}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>

                        <IconButton
                          className="btn_cyan_style"
                          aria-label="delete"
                          sx={{ borderRadius: "10px!important", ml: 1 }}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    }
                  />
                </ListItem>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <Box
                    component="img"
                    alt="image"
                    className="history_image"
                    src={row.image}
                    sx={{
                      boxShadow: 3,
                      width: "inherit",
                      borderRadius: "10px",
                    }}
                  ></Box>
                  <Button
                    className="btn_cyan_style"
                    sx={{ width: "100%", marginTop: "0.5rem" }}
                    startIcon={<PaymentIcon />}
                  >
                    Pay Now
                  </Button>
                  <Box
                    sx={{
                      width: "inherit",
                      border: "1px solid #aaa",
                      borderRadius: "10px",
                      padding: "0.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "0.5rem",
                    }}
                  >
                    <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                      Active Date
                    </span>
                    <span>{row.active_date}</span>
                  </Box>
                  <Box
                    sx={{
                      width: "inherit",
                      border: "1px solid #aaa",
                      borderRadius: "10px",
                      padding: "0.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "0.5rem",
                    }}
                  >
                    <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                      Expire Date
                    </span>
                    <span>{row.expire_date}</span>
                  </Box>
                  <Box
                    sx={{
                      width: "inherit",
                      border: "1px solid #aaa",
                      borderRadius: "10px",
                      padding: "0.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "0.5rem",
                    }}
                  >
                    <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                      Status
                    </span>
                    <Button
                      component="span"
                      sx={{ textTransform: "capitalize", p: 0 }}
                      color={status_color}
                    >
                      {row.status}
                    </Button>
                  </Box>
                </Collapse>
              </div>
            );
          })}
        </List>
      )}
    </>
  );
};
