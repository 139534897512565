import React, { useEffect, useState } from "react";
import "./VehicleType.scss";
import { Box, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import vehicleIdImg from '../../assets/images/Rectangle 5 (1).svg';
import vehicleIdImg1 from '../../assets/images/Rectangle 5 (2).svg';
import vehicleIdImg2 from '../../assets/images/Rectangle 5 (3).svg';
import vehicleIdImg3 from '../../assets/images/Rectangle 5 (4).svg';
import vehicleIdImg4 from '../../assets/images/Rectangle 5 (5).svg';
import { Link } from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent } from "@material-ui/core";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const itemData = [
    {
        img: vehicleIdImg,
        title: "Car"
    },
    {
        img: vehicleIdImg3,
        title: "Commercial Car"
    },
    {
        img: vehicleIdImg4,
        title: "Motorhouse"
    },
    {
        img: vehicleIdImg1,
        title: "Trailer"
    },
    {
        img: vehicleIdImg2,
        title: "Truck"
    }
];

const styles = theme => ({
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    container: {
        position: 'relative',
        textAlign: 'center',
        color: 'white',
    },
    topasad: {
        color: 'blue',
        position: 'absolute',
        top: '10%',
        left: '50%',
    },

    media: {
        display: 'flex',
        height: 152,
        objectFit: 'contain',
        alignItems: 'left',

    },
})

function VehicleType(props) {
    const matches = useMediaQuery("(min-width:600px)");
    const [showSideBar, setShowSideBar] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const { classes } = props;
    return (
        <>
            <div id="SellCar1">
                <Box sx={{ mt: 3, mb: 3 }}>
                    <h3
                        className="section_title_cyan max_size_cyan"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                        }}
                    >
                        {matches ? null : (
                            <MenuIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowSideBar(true)}
                            />
                        )}
                        Select Vehicle Type
                    </h3>
                </Box>
                <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center" }}>
                    {itemData.map((item) => (
                        <Card>
                            <Link to={`/sell_car2?id=${id}`} style={{ color: "#00aeef", textDecoration: "none" }}>
                                <div className={classes.con}>
                                    <CardContent className={classes.content}>
                                        <div style={{ position: 'relative' }} >
                                            <CardMedia
                                                component="img"
                                                className={classes.media}
                                                image={item.img}
                                            />
                                            <h2 style={{ position: 'absolute', color: 'white', top: 40, left: '50%', transform: 'translateX(-50%)' }} >{item.title}</h2>
                                        </div>
                                    </CardContent>
                                </div>
                            </Link>
                        </Card>))}
                </Box>
                <Box style={{ textAlign: "center" }} sx={{ pb: 6 }}>
                    <div>Already have an ID ?</div>
                    <br />
                    <Link to={`/sell_car?id=${id}`} style={{ color: "#00aeef", textDecoration: "none" }}>
                        Enter ID info
                    </Link>
                </Box>
            </div>
        </>
    );
}

export default withStyles(styles)(VehicleType);
