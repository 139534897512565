import React from "react";
import { Box } from "@mui/material";
import "../../static/css/style_helper/style_helper.scss";
function PrivacyPolicy(props) {
  return (
    <div id="PrivacyPolicy">
      <Section1></Section1>
    </div>
  );
}

export default PrivacyPolicy;

function Section1() {
  return (
    <section
      id="section1"
      className="bg_img_section"
      style={{
        "--bg-section-img": `url(${require("../../static/img/35cf026bb0dded565441fed7aee42ae0.jpg")})`,
      }}
    >
      <Box sx={{ py: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">Term and conditions with Privacy</h3>
        <div className="section_wrapper section_space_wrapper">
          <Box sx={{ pt: 5 }}>
            <h3 className="title_line section_title_lg">Term and conditions with Privacy</h3>
            <p className="paragraph_space1">
              <h1>1. Scope</h1>
              <div>
                1. These General Terms and Conditions (GTC) rules the contractual relationships between the company Autoby 24 GmbH, Hagenholzstrasse 81a, in 8050 Zurich (hereinafter referred to as 'provider') and its users (hereinafter referred to as 'users') for those on the platform www.autoby24.ch and The associated apps (Android, Apple) (hereinafter referred to as 'platform' or 'apps') provided offers and services provided.
              </div>
              <div>
                2. The supplier operates a platform on the aforementioned website and apps on which it provides users with their infrastructure. The use of the platform allows transport communities in Switzerland and abroad to easily combine by promoting optimized rides and needs-based common transports with an easy-to-use application. The platform is provided under the platform according to para. 1 provided by the associated IOS and Android apps: users can conveniently arrange carpooling, schedule trips and share the travel costs.
              </div>
              <div>
                3. These provisions apply to natural as well as legal persons.
              </div>
              <div>
                4. These terms and conditions rules exclusively the contractual relationship between the provider and its users in the scope of these terms and conditions. There is no entitlement to a user at registration, membership, use of the platform or use of the services of the provider. In particular, the supplier is free to relocate a registration at any time, or in accordance with the following para. To exclude a user, to ban a use or to set a service.
              </div>
              <div>
                5. With reference to the preceding point. 2 is an infrastructure platform: In this sense, the provider provides its website or apps for use by users in the aforementioned context (see point 2 and § 2 ff.). For all claims of the users who arise in connection with the offer of a carpool, the supplier can not be made liable and there are no warranty claims in this context to the provider. With regard to the implementation of the carpooling itself, therefore, an independent contractual relationship between the users arises: any claims for poor performance, injury, damages, etc. In connection with the execution of the career offer, it is asserted under the users themselves, whereby the provider in this context is completely harmless in this context to hold. The warranty of the supplier consists in the description described below only for the provision of the website and the app.
              </div>
              <div>
                6. Conflicting or deviating from these terms and conditions are not recognized unless the providers have expressly agreed to them in the case of individual cases in writing. For all users, these terms and conditions apply exclusively, regardless of whether access to the website of the provider and the use of the website is made from / in Switzerland or abroad.
              </div>
              <div>
                7. The provider can complement or change the terms and conditions at any time at its sole discretion. The (approved) version at the time of use of the offer applies (request for a carpooling via the platform or the app). With the registration of a user, the acceptance or the request of a carpooling opportunity as well as by clicking on the associated box, the user undertakes automatically to accept and valid these terms and conditions.
              </div>
              <div>
                8. For the use of the platform, users undertake each other as well as to the provider of truthful and complete information in the context of use. If this should not be complied with by a user, the provider expressly reserves the right to reject the registration or registration or other activity of a user via the platform or the app.
              </div>
              <h1>2. Registration on the platform / app</h1>
              <div>
                1. The platform is generally accessible. However, registration requires the completion of the 18th annual age. With access to the platform, its use or registration, the user confirms the 18th year of life.
              </div>
              <div>
                2. The decomposition of an account on the platform or the app requires the following:
                <div>
                  - Filling the registration form and truthful specification of all the required information on the website
                </div>
                <div>
                  - Registration about downloading the app via the AppStore or the Playstore (Android) and subsequent truthful completion of the registration form
                </div>
                <div>
                  - Approval of the terms and conditions and the privacy policy
                </div>
              </div>
              <div>
                3. With the registration, the user undertakes to truthful information, therefore to specify the correct name, the correct address as well as the actual, valid and requested contact data (existing telephone number and e-mail). With your registration as well as by clicking on the associated box, users undertake automatically to accept and scope these terms and conditions.
              </div>
              <div>
                4. If the conditions according to the preceding § 2 point. 1 et seq. 1 ff. By a user not complied with, the provider expressly reserves the right to block the access of an already registered user and to deny the further or the first user access to an indefinite time. The assertion of damages or the compensation of the resulting damage remains expressly reserved.
              </div>
              <div>
                5. The users are also obliged to regularly examine the data on their user account and to update or supplement if necessary. At the request of the provider, the correctness of the information provided by the specified information must be demonstrated. The provider reserves the right to make samples on the accuracy of the stored data.
              </div>
              <div>
                6. Users receive a stand-alone login with changeable password. Users are obliged to protect their user account from abuse by third parties and keep their access data safely at any time. If non-compliance, the user can be held liable.
              </div>
              <div>
                7. The existing after successful registration account of a user is personal and non-transferable.
              </div>
              <h1>3. Use of the service / cost and fees</h1>
              <div>
                1. The offer of the supplier or the uses and sequences on the platform or the apps is available under the following, contract integral link:<a>www.autoby24.ch</a>
              </div>
              <div>
                2. For the publication of an offer for a carpool, the following conditions must be fulfilled in advance:
                <div>
                  a. The offering user must have a valid driver's certificate; Likewise, the offering user is fully authorized to drive;
                </div>
                <div>
                  b. The offering user must be able to dispose of the vehicle used for the offer or have an explicit permit to use the vehicle;
                </div>
                <div>
                  c. The vehicle used for the offer is properly and sufficiently insured according to the statutory requirements of the country of use;
                </div>
                <div>
                  d. The offer includes no longer places, as available in the vehicle of the offering user - therefore, when carrying out the driving in this context at any time, the security of the riders ensured due to the space conditions;
                </div>
                <div>
                  e. All offered places in the vehicle (passenger car or bus) have a seatbelt;
                </div>
                <div>
                  f. The vehicle is in perfect condition and is approved in accordance with the statutory provisions of the service center MFK-tested or for road use;
                </div>
                <div>
                  g. The offering user is not a commercial service provider for taxi rides or other transport services or the offer is not for commercial use. A violation of this rule entitles the providers to assert damages or compensation all associated damage.
                </div>
              </div>
              <div>
                3. The offering users are exclusively responsible for any content of the passenger offered in their on the platform or the app. The supplier can not assume any liability for this, which is known to users and what they accept.
              </div>
              <div>
                4. The offers of users are publicly opened on the platform or the app and are visible to anyone. For this purpose, the offering users expressly agree.
              </div>
              <div>
                5. Participation in as well as all actions as part of the publication of an offer always presupposes the prior registration of a user with his user account.
              </div>
              <div>
                6. The platform or app provides a valuation system in which users can evaluate each other according to the service. The evaluations must be fair and in particular the following rules according to § 2 point. 7. Then the users are obliged to formulate their ratings conscientiously, objectively and benevolent. The provider is entitled to clarify or not to consider doubtful evaluations according to its own assessment.
              </div>
              <div>
                7. Any communication among users on the or via the platform or app as well as the implementation of a service offered must necessarily comply with the following conditions:
                <div>
                  a. It is prohibited from the users to encourage other persons on the content of them by using the content published on the platform, making or distributing or distributing or distributing pornographic and other youth-dangerous material. Likewise, the adjustment and the dissemination of racist, sexist and violent contents are prohibited. This also applies to content that should motivate for terrorist activities or other criminal and illegal actions or advocate such actions in some way. Furthermore, it is forbidden to the users to set content that aims at dating or sexual contacts. Also prohibited is the collection for advertising for other companies or persons than the users themselves: In particular, the linking to commercial and non-commercial offers from other providers or other persons as the Contracting Parties is prohibited;
                </div>
                <div>
                  b. Using the platform must not be made for commercial and commercial purposes. This means that the remuneration of the offering user has restricted to the principles on the deployment of his costs incurred for the ride service, whereby in detail § 3 point. 9 applies;
                </div>
                <div>
                  c. If the use of the platform or app differs significantly from average use or there is suspicion of legal and misconductive behavior, the provider may stop the user for legal and usable use.
                </div>
              </div>
              <div>
                8. It is also prohibited from the users in mutual exchange during (as well as outside) the use of the platform / app and on the occasion of the implementation of the vehicle service, obscene or otherwise harassing as well as criminal relevant statements to spread or criminal content relevant.
              </div>
              <div>
                9. Users are then obliged to bypass the offer on the platform or app, in particular by attempting to make a booking outside the platform by sending their contact details to another member or bus company. Thus, they are obliged to establish another user for another purpose than the purpose of a carpooling on the platform. Finally, users are obliged to accept or make any payments outside the platform in the same light, except for the cases approved in these terms and conditions.
              </div>
              <div>
                10. The cost of a career offer is determined as follows in individual cases:
                <div>
                  a. <b>Offer of a driver or offering user:</b> In addition to the above § 2 para. 7 lit. b) the lump sum is determined by the latter by the latter by the latter for traveling by a user. The cost framework of the offer form the actual operating costs of the vehicle, taking into account the output gasoline or energy expenses (for electric vehicles). In a carpool, the cost contribution is determined by you as a driver under your sole responsibility. It is strictly prohibited to use the platform in any way to make profit. They therefore undertake to limit the cost contribution whose payment they require their riders on the operating costs of the car that will effectively arise them by the carpool. Otherwise, they alone bear the risk as well as the consequences of the fact that their journey can represent a passenger transport under the passenger transport law, or other laws, forbidden passenger transport.
                </div>
                <div>
                  b. <b>Tourist Package on demand of a user:</b> The career user has the opportunity to make the moving, offering user an offer for the rental services or the passing user defines the price of the rental company.
                </div>
                <div>
                  c. <b>Price list / boundaries:</b> For the purposes of the preceding § 3 point 10 a) and (b), users can determine the prices themselves with each other. As a guideline for the pricing, a maximum price of 15 cents (0.15 EUR) applies per kilometer hazard.
                </div>
              </div>
              <div>
                11. For the provision of the platform or app and the infrastructure available on this, the provider is entitled to demand a fee. The fee is CHF / EUR 2.00 for runs up to a distance of 100 km, CHF / EUR 4 for runs up to a distance from 101 km to 400 km and CHF / EUR 5 from a distance of 401 km. With regard to the payment processing, the following § 4 applies.
              </div>
              <h1>4. Payment processing / cancellations</h1>
              <div>
                1. The fees for the ride-sharing service are paid to the driver by passengers traveling with them. For the use of the infrastructure of the platform / app, the driver or driving user will be charged a fee by the provider. All ride-sharing prices as well as the fees for the use of the infrastructure charged by the provider are displayed on the platform / app (Goby24 Service Charge). The travel costs and fees that have already been paid can be viewed on the dashboard.
              </div>
              <div>
                2. The infrastructure and mediation fees for the use of the platform / app is calculated as follows:
                <div>Trips up to 100 km 2 euros or 2 CHF</div>
                <div>Rides from 101 km to 400 km 4 Euro OR 4 CHF</div>
                <div>Rides over 400 km 5 Euro or 5 CHF</div>
              </div>
              <div>
                3. The on-run cost of the journey carried out is 10 cents per kilometer hazard and can be increased by a maximum of 5 cents per kilometer driven by the driver. The first 1 - 25 kilometers costs in each case 5 euros.
                Example: 100 km * 10 = 10 €
                The driver can increase the price to 15 cents, so 100 * 15 = 15 €
                Trips up to 100 km 2 euros or 2 CHF
              </div>
              <div>
                4. Can be paid via the platform by credit card (Visa, MasterCard) or PayPal or Twint. The user will be forwarded to the corresponding payment processing page when booking via the platform / app.
              </div>
              <div>
                5. The journey offers of the 'Tourist Pack' can only be paid via e-payment (card or twint). The 'tourist package' can only be canceled in an emergency, both from driver and provider. The service fees of the supplier for the 'Tourist Package' are between 5% and 10%. 5% applies to the carriage of up to 4 people and 10% for the carriage of 5 to 10 people.
              </div>
              <div>
                6. Moreover, the following conditions apply in the case of cancellations:
                <div>a. <b>In case of cancellation of a driver or offering user:</b> Cancellations are only possible if there is an emergency situation that it does not allow the driver in good lens to perform the offer.</div>
                <div>b. <b>In case of cancellation of a traveler or a user who wants to accept the carpooler:</b> A traveler can cancel the offer for up to 5 hours prior to the agreed ride without consequences (cancellation reason must be stated on the platform; In case of insufficient reason, a bad rating can be made by the driver and the supplier can lock the user if necessary in the future); In case of cancellation shorter than 5 hours before the agreed departure, the service is to be paid as intended.</div>
                <div>c. <b>Reimbursement process:</b> With agreed cash and timely cancellation according to the preceding point. 3 b), no prizeization is necessary. If the payment has been made online via the platform, the reimbursement will be displayed with 'reimbursement' when canceling the ride on the platform. Thereafter, the administrator in the website of the Website is a reimbursement request. If the administrator approves the reimbursement request, the customer will receive its reimbursement and the reimbursement process is completed and the status is set to 'Reimbursement'.</div>
              </div>
              <div>
                7. Price changes and price ratings remain reserved.
              </div>
              <h1>5. Liability / warranty exclusion of the provider</h1>
              <div>
                1. The basis of any liability issues form the above statements. The provider assumes no responsibility for the content and the correctness of the information associated with all by the users themselves.
              </div>
              <div>
                2. With the publication of an offer and the execution of a ride comes a legal relationship between the users, which is independent of the supplier. The provider is not liable for related contract infringements or warranty claims of the parties, so the supplier is not liable for the legally binding, legally compliant implementation of the co-entry offer provided by the platform usage. In this way, all concerns / legal entitlements from the cancellation relationship between the users can be directed directly to these themselves, this includes in particular any payment obligations or other rights / claims from the contract, penalty, but also liability and insurance law (warranty rights, liability claims, claims arising from extra contractual Liability, especially in the case of accidents on the occasion of the execution of a ride).
              </div>
              <div>
                3. If a user after successful mediation of a carpooling opportunity and then on the occasion of the implementation of an exit all for safety and successful travel per se appropriate or even displayed measures, the supplier, however, reserves the right to provide the right to display the other user faithful users from the use of the platform immediately to block indefinitely. However, a binding claim there is no.
              </div>
              <div>
                4. Liability for damage caused by damages is excluded in the context of the legally possible. In the following further cases, any liability of the provider is excluded:
                <ul>
                  <li>
                  Damage and consequential damage as a result of the non-availability or as a result of interruptions on the website
                  </li>
                  <li>
                  Damage caused by viruses or similar damage caused as a result of the use of the website or via the use of the website
                  </li>
                </ul>
              </div>
              <div>
                5. The user is solely responsible for all content and information he provides as part of the registration or otherwise available on the website or price. In this context, the user is also responsible for all information in connection with the information made by him, excluding any liability of the provider.
              </div>
              <div>
                6. The user assures that the data of the truth provided by him corresponds to deceptions regarding the identity of a user, the opening of multiple user accounts as well as the performance of false information over other users constitute a serious violation of the present conditions and the infringing user can be liable for this purpose be made.
              </div>
              <div>
                7. The user also assures that he did not use the data entrusted to him through the website / app or by the provider of third parties to other commercial purposes independent of the use of the platform (especially for advertising purposes). He alone is responsible for their responsibility for content / images published by him, if he violates trademark rights or copyrights of third parties.
              </div>
              <div>
                8. The user undertakes to malfunction against receivables and any complaints of third parties (natural or legal persons), which arise as a result of a relating to the present provisions or otherwise improper use of the platform.
              </div>
              <div>
                9. The user undertakes the provider of all claims to keep the third against them due to the infringement of a user against a statutory provision associated with the execution of a ride. In the event of the assertion of such claims, the user is obliged to immediately and fully participate in the approval of the facts and to make the provider accessible to the information required for this purpose.
              </div>
              <div>
                10. The provider makes the technical support as far as possible and exclusively via telephone connection or e-mail. However, the supplier does not guarantee the steady availability of such a service and concrete of the availability of the auction platform. Technical access (internet connection, etc.) to the platform is the responsibility of the customer. The provider assumes no liability for the network operators and rejects any liability for the required hardware and software of the customer within the framework of the legally possible.
              </div>
              <h1>6. Privacy / data usage and commercial data usage</h1>
              <div>
                1. The data of the users needed to use the aforementioned platform will be treated confidentially under preservation of Swiss data protection. With regard to all data protection matters, the separate Data protection pointed out..
              </div>
              <h1>7. Property and copyright</h1>
              <div>
                1. At all image and font motifs presented on the website and on the system program on the platform, the supplier has copyright.
              </div>
              <div>
                2. All content on the website (such as information, pictures, texts, logos, brands, designs, user interfaces) are the sole property and copyright of the provider, unless otherwise specified. It is strictly prohibited to copy, modify, forward, publish, or duplicate these contents or partial contents. This also applies to the graphical representations on the website as well as the programming, the structure and the structural and conceptual design of the website as well as the related individual functions itself, which are protected by copyright by the provider and can not be copied from a user or other third parties .
              </div>
              <div>
                3. In the case of an unauthorized violation against ownership, copyright and trademark rights, the supplier reserves the assertion of damages, profit issue and loss of profit.
              </div>
              <h1>8. Further rights of the provider</h1>
              <div>
                1. The provider is entitled to carry out the following other actions - in addition to the aforementioned rights:
                  <div>
                    Right to block a user account in case of non-compliance with the aforementioned Terms of Use
                  </div>
                  <div>
                    Right of passing on the information made and stored by a user on the platform in the case of a sales or part sales of the platform to a third-party buyer, provided that the use of the data for the same purpose or the same taste filling persists
                  </div>
                  <div>
                    At any time, not pre-announced law the website or the services change
                  </div>
                  <div>
                    Right on at all times obtaining a criminal code of a user, with reasonable suspicion of potential breach of the aforementioned Terms of Use
                  </div>
              </div>
              <h1>9 Final provisions</h1>
              <div>
                  1. For all concerns in connection with the present terms and conditions, exclusively Swiss law is applicable to exclusion of the UN sales law.
              </div>
              <div>
                  2. Jurisdiction for disputes arising from the present contract - reserved for compulsory courts - is Zurich (ZH, Switzerland) or the court responsible for dispute.
              </div>
              <div>
                  3. If a party waives to assert or enforce a contractually responsible right in individual cases, it can not be derived from a general renunciation of the law.
              </div>
              <div>
                  4. Should one or more clauses of these terms and conditions be insistently or partially ineffective, this is not affected by the validity of the remaining contract provisions. The ineffective or void determination is to be replaced by a new, legally permissible provision, which is as close as possible in its economic impact of the ineffective or nullary provision. If an ineffective contract clause is not subsequently corrected, a relevant statutory provision occurs in its place.
              </div>
            </p>
          </Box>
        </div>
      </Box>
    </section>
  );
}
