import React, { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./VipAdvertisement.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SvgIcon from "@mui/material/SvgIcon";
import { packageSubscriptionHttpRequest } from "../../static/http-content";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function VipAdvertisement(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  const [tabIndex, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div id="CarAdvertisement">
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          VIP Advertisement Packages
        </h3>
      </Box>
      <Box align="center" display="flex" justifyContent="center">
        <Tabs value={tabIndex} onChange={handleChange} scrollButtons="auto">
          <Tab
            disableRipple
            label={
              <span
                style={{
                  fontWeight: 800,
                  color: tabIndex === 0 ? "#00aeef" : "",
                }}
              >
                ALL
              </span>
            }
            wrapped
          />
          <Tab
            disableRipple
            label={
              <span
                style={{
                  fontWeight: 800,
                  color: tabIndex === 1 ? "#00aeef" : "",
                }}
              >
                SUBSCRIBED
              </span>
            }
            wrapped
          />
          <Tab
            disableRipple
            label={
              <span
                style={{
                  fontWeight: 800,
                  color: tabIndex === 2 ? "#00aeef" : "",
                }}
              >
                EXPIRED
              </span>
            }
            wrapped
          />
        </Tabs>
      </Box>
      <Box
        display="flex"
        sx={{
          pb: 8,
          background: "var(--auto-text-f1)",
          ml: matches ? "260px" : "0px",
        }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: 4 }}>
          {tabIndex === 0 ? <CarPackageAd></CarPackageAd> : null}
          {tabIndex === 0 && <CustomizePackage></CustomizePackage>}
          {tabIndex === 1 ? <SubscribedPackageAd></SubscribedPackageAd> : null}
          {tabIndex === 2 ? <ExpiredCarPackageAd></ExpiredCarPackageAd> : null}
        </Box>
      </Box>
    </div>
  );
}

export default VipAdvertisement;

const SubscribedPackageAd = () => {
  const navigate = useNavigate();
  const package_object = [
    {
      id: 1,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 2,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 3,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 4,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
  ];
  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 3, sm: 6 }}>
      {package_object.map((v) => (
        <Grid item xs={12} sm={6} key={v.id}>
          <Item sx={{ boxShadow: 3, borderRadius: 4, px: 3 }}>
            <Box>
              <h2 className="title_line">{v.title}</h2>
            </Box>
            <Box>
              <table className="package_table table_form">
                <tbody>
                  <tr>
                    <td>DURATION</td>
                    <td>
                      <div className="cyan_value_box">{v.duration}</div>
                    </td>
                  </tr>

                  <tr>
                    <td>PRICE</td>
                    <td>
                      <div className="cyan_value_box">{v.price}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <Box sx={{ my: 3 }}>
              <Button
                type="button"
                sx={{ py: 0, px: 3, mr: 2 }}
                className="btn_cyan_style"
                disabled
              >
                Subscribed
              </Button>
              <Button
                type="button"
                sx={{ py: 0, px: 3 }}
                className="btn_cyan_style"
                // onClick={() => navigate({ pathname: '/sell_car', search: `?id=${v.package}` })}
                onClick={() => navigate("/vip_advertisement_history")}
              >
                Add Advertisement
              </Button>
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};

const CarPackageAd = () => {
  const package_object = [
    {
      id: 1,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 2,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 3,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 4,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
  ];
  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 3, sm: 6 }}>
      {package_object.map((v) => (
        <Grid item xs={12} sm={6} key={v.id}>
          <Item sx={{ boxShadow: 3, borderRadius: 4, px: 3 }}>
            <Box>
              <h2 className="title_line">{v.title}</h2>
            </Box>
            <Box>
              <table className="package_table table_form">
                <tbody>
                  <tr>
                    <td>DURATION</td>
                    <td>
                      <div className="cyan_value_box">{v.duration}</div>
                    </td>
                  </tr>

                  <tr>
                    <td>PRICE</td>
                    <td>
                      <div className="cyan_value_box">{v.price}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <Box sx={{ my: 3 }}>
              <Button
                sx={{ py: 0, px: 3 }}
                className="btn_cyan_style"
                component={Link}
                to={v.link}
              >
                TAKE PACKAGE
              </Button>
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};

const ExpiredCarPackageAd = (props) => {
  const intl = useIntl();
  const submit = (v) => {
    packageSubscriptionHttpRequest
      .post(
        `/${v.id}/reactivate/`,
        { package: v.package },
        {
          headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
        toast.update(toast_id, {
          render: "Reactivated Package Successfully",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
        packageSubscriptionHttpRequest
          .get("/", {
            headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
          })
          .then((res) => {
            props.setSubscribedPackageData(res.data.data);
          })
          .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err.response));
  };
  return (
    <>
      <Grid container rowSpacing={6} columnSpacing={{ xs: 3, sm: 6 }}>
        {props?.subscribedPackageData.map(
          (v) =>
            v.is_expired === true && (
              <Grid item xs={12} sm={6} key={v.id}>
                <Item sx={{ boxShadow: 3, borderRadius: 4, px: 3 }}>
                  <Box>
                    <h2 className="title_line">
                      {v?.package_detail?.package_name}
                    </h2>
                  </Box>
                  <Box>
                    <table className="package_table table_form">
                      <tbody>
                        <tr>
                          <td>PRICE</td>
                          <td>
                            <div className="cyan_value_box">{v.price} CHF</div>
                          </td>
                        </tr>
                        <tr>
                          <td>PERIOD</td>
                          <td>
                            <div className="cyan_value_box">
                              {v?.package_detail?.validity_days} DAYS
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>NO OF VEHICLE</td>
                          <td>
                            <div className="cyan_value_box">
                              {v.number_of_vehicle}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>IMAGES</td>
                          <td>
                            <div className="cyan_value_box">
                              UP TO {v.number_of_images}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>

                  <Box sx={{ my: 3 }}>
                    <Button
                      type="button"
                      sx={{ py: 0, px: 3 }}
                      className="btn_cyan_style"
                      onClick={() => submit(v)}
                    >
                      RENEW
                    </Button>
                  </Box>
                </Item>
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

const CustomizePackage = () => {
  return (
    <Box textAlign="center" sx={{ mt: 5 }}>
      <Item sx={{ boxShadow: 3, borderRadius: 4, px: 3 }}>
        <Box textAlign="center">
          <h2 className="title_line">
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="56"
                viewBox="0 0 72 56"
              >
                <path
                  id="account-help"
                  d="M40.273,18c0,7.732-5.861,14-13.091,14S14.091,25.732,14.091,18,19.952,4,27.182,4s13.091,6.268,13.091,14M53.364,53v7H1V53c0-7.735,11.716-14,26.182-14s26.182,6.265,26.182,14M64.818,40.75V46H59.909V40.75h4.909m-6.545-17.5H53.364V21.5c0-5.8,4.4-10.5,9.818-10.5S73,15.7,73,21.5a10.442,10.442,0,0,1-4.222,8.435L67.8,30.6a7.35,7.35,0,0,0-2.978,5.95v.7H59.909v-.7a12.6,12.6,0,0,1,5.2-10.325l.949-.665a4.945,4.945,0,0,0,2.029-4.06,4.92,4.92,0,1,0-9.818,0Z"
                  transform="translate(-1 -4)"
                  fill="#00aeef"
                />
              </svg>
            </SvgIcon>
          </h2>
        </Box>
        <Box sx={{ my: 2, textAlign: "center" }}>
          <span> Want a Custom Package ? </span>
          <Link
            className="line_decoration"
            style={{ color: "#00aeef", textDecoration: "none" }}
            to="/customize_pakage"
          >
            Click here
          </Link>
        </Box>
      </Item>
    </Box>
  );
};
