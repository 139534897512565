import React, { useState, useRef } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./VipAdvertisementHistory.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import {
  getAllPackagesHttpRequest,
  getPackagesListHttpRequest,
  downloadCsvHttpRequest,
  importCSVFileHttpRequest,
  importExcelFileHttpRequest,
} from "../../static/http-content";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function VipAdvertisementHistory(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="AdvertisementHistory"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            maxWidth: "350px",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          VIP Advertisement History
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 6 : 1, maxWidth: "100%" }}>
          <Box
            id="historyBox"
            sx={{
              boxShadow: 3,
              p: 6,
              borderRadius: 3,
              background: "var(--auto-text-white)",
            }}
          >
            <BoxHead></BoxHead>
            <BoxBody></BoxBody>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default VipAdvertisementHistory;

const BoxHead = (props) => {
  const btn_action_object = [
    // {
    //   id: 1,
    //   name: "CSV",
    //   icon: <DownloadIcon></DownloadIcon>,
    //   link: "",
    // },
    // {
    //   id: 2,
    //   name: "Excel",
    //   icon: <DownloadIcon></DownloadIcon>,
    //   link: "",
    // },
    {
      id: 3,
      name: "Add VIP Advertisement",
      icon: <AddIcon />,
      link: "/add_vip_advertisement",
    },
    // {
    //   id: 4,
    //   name: "Import CSV",
    //   icon: <CloudDownloadIcon></CloudDownloadIcon>,
    //   link: "",
    // },
    // {
    //   id: 5,
    //   name: "Import Excel",
    //   icon: <CloudDownloadIcon></CloudDownloadIcon>,
    //   link: "",
    // },
    // {
    //   id: 6,
    //   name: "Download History",
    //   icon: "",
    //   link: "",
    // },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const intl = useIntl();
  const inputRef1 = useRef(null);
  const inputRef = useRef(null);
  const [Import, setImport] = useState(0);
  const [Export, setExport] = useState(0);
  const handleChange = (event) => {
    setImport(event.target.value);
    if (event.target.value == 10) {
      downloadCsvHttpRequest
        .get(
          `/?_export=yes&export_format=csv&package_subscription__package=${id}`,
          {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "vehicle.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => console.log(err.response));
    } else if (event.target.value == 20) {
      downloadCsvHttpRequest
        .get(
          `/?_export=yes&export_format=xlsx&package_subscription__package=${id}`,
          {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "vehicle.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => console.log(err.response));
    }
  };
  const handleChanges = (event) => {
    setExport(event.target.value);
    if (event.target.value == 10) {
      inputRef.current?.click();
    } else if (event.target.value == 20) {
      inputRef1.current?.click();
    }
  };
  const changeCsvHandler = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    formData.append("file", inputRef.current?.files[0]);
    formData.append("package", id);
    importCSVFileHttpRequest
      .post("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        getAllPackagesHttpRequest
          .get(`/${id}/`, {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            props?.setPackageDetail(res?.data?.data);
            getPackagesListHttpRequest
              .get("/?package_subscription__package=" + id, {
                headers: {
                  Authorization: `jwt ${localStorage.getItem("token")}`,
                },
              })
              .then((res) => {
                props?.setPackageData(res.data.data);
                if (
                  res?.data?.data.length ===
                  props?.packageDetail?.number_of_vehicle
                ) {
                  props?.setDisable(true);
                }
              })
              .catch((err) => console.log(err.response));
          })
          .catch((err) => console.log(err.response));
        toast.update(toast_id, {
          render: "CSV Import Successfully",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err.response));
  };
  const changeExcelHandler = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    formData.append("file", inputRef1.current?.files[0]);
    formData.append("package", id);
    importExcelFileHttpRequest
      .get("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        getAllPackagesHttpRequest
          .get(`/${id}/`, {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            props?.setPackageDetail(res?.data?.data);
            getPackagesListHttpRequest
              .get("/?package_subscription__package=" + id, {
                headers: {
                  Authorization: `jwt ${localStorage.getItem("token")}`,
                },
              })
              .then((res) => {
                props?.setPackageData(res.data.data);
                if (
                  res?.data?.data.length ===
                  props?.packageDetail?.number_of_vehicle
                ) {
                  props?.setDisable(true);
                }
              })
              .catch((err) => console.log(err.response));
          })
          .catch((err) => console.log(err.response));
        toast.update(toast_id, {
          render: "Excel Import Successfully",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err.response));
  };
  return (
    <Box id="BoxHead">
      <form action="" className="responsiveBoxHead form_history_search">
        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">Package Name:</div>
          <div>ABC</div>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">From Date:</div>
          <HistoryDate></HistoryDate>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">To Date:</div>
          <HistoryDate></HistoryDate>
        </Box>
        {/* <Box className="form_group" sx={{ justifyContent: "flex-end" }}>
          <Button
            className="btn_cyan_style"
            sx={{ py: 0.5, px: 3, width: matches ? "auto" : "100%" }}
          >
            SUBMIT
          </Button>
        </Box> */}
      </form>
      <Box className="btn_download_wrapper" sx={{ my: 3, display: "flex", }}>
        {btn_action_object.map((v) => (
          <Button
            key={v.id}
            className="btn_cyan_style"
            sx={{ fontSize: "large", py: 0, px: 2, mr: 1 }}
            component={Link}
            to={v.link}
          >
            {v.icon}
            {v.name}
          </Button>
        ))}
        <Box sx={{ mr: 1, justifyContent: "end" }}>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ background: "#00aeef", color: "#fff", borderRadius: "20px" }}
              SelectDisplayProps={{
                style: { paddingTop: 4, paddingBottom: 4 },
              }}
              value={Import}
              className="form_group icon-white"
              label="Export"
              onChange={handleChange}
            >
              <MenuItem value={0} style={{ display: "none" }}>Export</MenuItem>
              <MenuItem value={10}>CSV</MenuItem>
              <MenuItem value={20}>Excel</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ background: "#00aeef", color: "#fff", borderRadius: "20px" }}
              SelectDisplayProps={{
                style: { paddingTop: 4, paddingBottom: 4 },
              }}
              value={Export}
              className="form_group icon-white"
              label="Import"
              onChange={handleChanges}
            >
              <MenuItem value={0} style={{ display: "none" }}>Import</MenuItem>
              <MenuItem value={10}>CSV</MenuItem>
              <MenuItem value={20}>Excel</MenuItem>
            </Select>
          </FormControl>
          <input
            ref={inputRef}
            type="file"
            name="file"
            accept=".csv"
            onChange={changeCsvHandler}
            style={{ display: "none", margin: "10px auto" }}
          />
          <input
            ref={inputRef1}
            type="file"
            name="file"
            accept=".xlsx"
            onChange={changeExcelHandler}
            style={{ display: "none", margin: "10px auto" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const HistoryDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      className="datePicker"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

function createData(id, image, active_date, expire_date, status, action) {
  return { id, image, active_date, expire_date, status, action };
}

const rows = [
  createData(
    1,
    require("../../static/img/Footer.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Active",
    2
  ),
  createData(
    2,
    require("../../static/img/Footer.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Pending",
    2
  ),
  createData(
    3,
    require("../../static/img/Footer.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Active",
    2
  ),
  createData(
    4,
    require("../../static/img/Footer.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Active",
    2
  ),
  createData(
    5,
    require("../../static/img/Footer.jpg"),
    "Jun 01, 2021",
    "Jun 16, 2021",
    "Active",
    2
  ),
];

const BoxBody = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {matches ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="history_table"
            className="history_table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontStyle: "italic" }}>#</TableCell>
                <TableCell align="center">IMAGE</TableCell>
                <TableCell align="center">
                  ACTIVE <br /> DATE
                </TableCell>
                <TableCell align="center">
                  EXPIRE <br /> DATE
                </TableCell>
                <TableCell align="right">STATUS</TableCell>
                <TableCell align="right">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                let status_color;
                switch (row.status.toLowerCase()) {
                  case "active":
                    status_color = "success";
                    break;
                  case "pending":
                    status_color = "error";
                    break;
                  default:
                    status_color = "black";
                }
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <Box
                        component="img"
                        alt="image"
                        className="history_image"
                        src={row.image}
                        sx={{ boxShadow: 3 }}
                      ></Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold" }}
                      component="td"
                    >
                      {row.active_date}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold" }}
                      component="td"
                    >
                      {row.expire_date}
                    </TableCell>

                    <TableCell
                      component="td"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      <Button
                        component="span"
                        sx={{ textTransform: "capitalize", p: 0 }}
                        color={status_color}
                      >
                        {row.status}
                      </Button>
                    </TableCell>

                    <TableCell align="center" component="td">
                      <IconButton
                        className="btn_cyan_style"
                        aria-label="delete"
                        sx={{ p: 1, mr: 1, borderRadius: "10px!important" }}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        className="btn_cyan_style"
                        aria-label="edit"
                        sx={{ p: 1, borderRadius: "10px!important" }}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List
          sx={{ width: "100%", padding: "0px" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {rows.map((row) => {
            let status_color;
            switch (row.status.toLowerCase()) {
              case "active":
                status_color = "success";
                break;
              case "pending":
                status_color = "error";
                break;
              default:
                status_color = "black";
            }
            return (
              <div
                style={{
                  borderBottom: "3px solid #00aeef",
                  paddingBottom: "1rem",
                }}
              >
                <ListItem>
                  <ListItemIcon style={{ fontWeight: "bold" }}>
                    # {row.id}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{ textTransform: "capitalize", p: 0 }}
                          color={status_color}
                        >
                          {row.status}
                        </Button>

                        <IconButton
                          className="btn_cyan_style"
                          aria-label="delete"
                          sx={{ p: 1, borderRadius: "10px !important" }}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                  />
                </ListItem>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <Box
                    component="img"
                    alt="image"
                    className="history_image"
                    src={row.image}
                    sx={{
                      boxShadow: 3,
                      width: "inherit",
                      borderRadius: "10px",
                    }}
                  ></Box>
                  <Box
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0.5rem",
                      marginBottom: "0.25rem",
                    }}
                  >
                    <span>Active Date</span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {row.active_date}
                    </span>
                  </Box>
                  <Box
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0.5rem",
                    }}
                  >
                    <span>Expire Date</span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {row.expire_date}
                    </span>
                  </Box>
                </Collapse>
              </div>
            );
          })}
        </List>
      )}
    </>
  );
};
