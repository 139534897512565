import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import Body from "../Body/Body";
import { ToastContainer } from "react-toastify";
import Locale from "../../utils/Locale";
import 'react-toastify/dist/ReactToastify.css';
import CommingSoon from "../../Pages/CommingSoon/CommingSoon"; 

function Base(props) {
  const getPathTitle = (pathname) => {
    switch (pathname) {
      case "/":
        return "Homepage | Autoby24";
      case "/login":
        return "Login | Autoby24";
      case "/register":
        return "Registration | Autoby24";
      default:
        return pathname;
    }
  };
  window.document.title = getPathTitle(useLocation().pathname);
  return (
    <div id="base">
  
      <ToastContainer />

      {/* <CommingSoon /> */}
      
      <Header></Header>
      
      <Body children={props.children}></Body>
      <Footer></Footer>
     
    </div>
  );
}

export default Base;
