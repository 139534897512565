import React, { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./CarAdvertisement.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function CarAdvertisement(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div id="CarAdvertisement">
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          Advertisement Packages
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{
          pb: 8,
          background: "var(--auto-text-f1)",
          ml: matches ? "260px" : "0px",
        }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: 6 }}>
          <CarPackageAd></CarPackageAd>
        </Box>
      </Box>
    </div>
  );
}

export default CarAdvertisement;

const CarPackageAd = () => {
  const package_object = [
    {
      id: 1,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 2,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 3,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 4,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 5,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 6,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 7,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
    {
      id: 8,
      title: "ATB15D",
      duration: "15 days",
      price: "100 CHF",
      link: "",
    },
  ];
  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 3, sm: 6 }}>
      {package_object.map((v) => (
        <Grid item xs={12} sm={6} key={v.id}>
          <Item sx={{ boxShadow: 3, borderRadius: 4, px: 3 }}>
            <Box>
              <h2 className="title_line">{v.title}</h2>
            </Box>
            <Box>
              <table className="package_table table_form">
                <tbody>
                  <tr>
                    <td>DURATION</td>
                    <td>
                      <div className="cyan_value_box">{v.duration}</div>
                    </td>
                  </tr>

                  <tr>
                    <td>PRICE</td>
                    <td>
                      <div className="cyan_value_box">{v.price}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <Box sx={{ my: 3 }}>
              <Button
                sx={{ py: 0, px: 3 }}
                className="btn_cyan_style"
                component={Link}
                to={v.link}
              >
                TAKE PACKAGE
              </Button>
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};
