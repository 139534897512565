import React from "react";
import { Box } from "@mui/material";
import "../../static/css/style_helper/style_helper.scss";
function CookiesPage(props) {
  return (
    <div id="CookiesPage">
      <Section1></Section1>
    </div>
  );
}

export default CookiesPage;

function Section1() {
  return (
    <section
      id="section1"
      className="bg_img_section"
      style={{
        "--bg-section-img": `url(${require("../../static/img/kevin-carter-vU5_sB3jqRA-unsplash.jpg")})`,
      }}
    >
      <Box sx={{ py: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">
          Cookies and Online Advertising
        </h3>
        <div className="section_wrapper section_space_wrapper">
          <Box sx={{ pt: 5 }}>
            <h3 className="title_line section_title_lg">What are Cookies?</h3>
            <p className="paragraph_space1">
              A cookie is a very small piece of information that a website
              stores to save and collect basic information. Many cookies are
              essential to the operation of our website, for example to allow
              you to make a purchase or create an account with us. Cookies on
              our site are also used to customise certain areas of content just
              for you, and to make your browsing experience more efficient and
              faster.
            </p>
          </Box>

          <Box sx={{ pt: 5 }}>
            <h3 className="title_line section_title_lg">How we use Cookies</h3>
            <p className="paragraph_space1">
              Cookies on our website are used for the following purposes
            </p>
            <ul>
              <li>To enable you to login the the system</li>
              <li>To enable you to system messages</li>
              <li>To improve the website's usability by saving loading time</li>
              <li>To improve the website's usability by saving loading time</li>
              <li>
                To analyse the use of our website through Analytics software
                such as Google Analytics.
              </li>
            </ul>
          </Box>

          <Box sx={{ pt: 5 }}>
            <h3 className="title_line section_title_lg">
              Information Collected
            </h3>
            <p className="paragraph_space1">
              The cookies our website doesn't collect any private data
            </p>
          </Box>
        </div>
      </Box>
    </section>
  );
}
