import React, { useState } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "../AdvertisementHistory/AdvertisementHistory.scss"; // import from AdvertisementHistory css
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";

import "./BookingHistory.scss";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function CarBookingHistory(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="AdvertisementHistory"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          Car Booking History
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 6 : 1, maxWidth: "100%" }}>
          <Box
            id="historyBox"
            sx={{
              boxShadow: 3,
              p: 6,
              borderRadius: 3,
              background: "var(--auto-text-white)",
            }}
          >
            <BoxHead></BoxHead>
            <BoxBody></BoxBody>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default CarBookingHistory;

const BoxHead = () => {
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery("(min-width:600px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event.target);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <Box id="BoxHead">
      <Box align="center" display="flex" justifyContent="center" sx={{ mb: 4 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          className="overlap_tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            disableRipple
            sx={{ color: "white" }}
            label="Pending Booking"
            wrapped
          />
          <Tab
            disableRipple
            sx={{ color: "white" }}
            label="Waiting Booking"
            wrapped
          />
          <Tab
            disableRipple
            sx={{ color: "white" }}
            label="Confirmed Booking"
            wrapped
          />
        </Tabs>
      </Box>
    </Box>
  );
};

const HistoryDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      className="datePicker"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

function createData(id, name, price, image, status, action) {
  return { id, name, price, image, status, action };
}

const rows = [
  createData(
    1,
    "BMW M3",
    "17000 CHF",
    require("../../static/img/Footer.jpg"),
    "Active",
    ""
  ),
  createData(
    2,
    "BMW M3",
    "17000 CHF",
    require("../../static/img/Footer.jpg"),
    "Active",
    ""
  ),
];

const BoxBody = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {matches ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, mb: rows.length == 0 && 30 }}
            aria-label="history_table"
            className="history_table"
          >
            <TableHead>
              <TableRow className="one_line_text">
                <TableCell style={{ fontStyle: "italic" }}>#</TableCell>
                <TableCell align="center">NAME</TableCell>
                <TableCell align="center">PRICE</TableCell>
                <TableCell align="center">IMAGE</TableCell>
                <TableCell align="center">STATUS</TableCell>
                <TableCell align="center">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="td" scope="row">
                    {row.id}
                  </TableCell>

                  <TableCell component="td" scope="row">
                    <Box
                      component="img"
                      alt="image"
                      className="history_image"
                      src={row.image}
                      sx={{ boxShadow: 3 }}
                    ></Box>
                  </TableCell>
                  <TableCell component="td" align="center" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="td"
                    sx={{ fontWeight: "bold" }}
                  >
                    {row.price}
                  </TableCell>

                  <TableCell
                    align="center"
                    component="td"
                    sx={{ fontWeight: "bold" }}
                  >
                    {row.status}
                  </TableCell>

                  <TableCell align="center" component="td">
                    <IconButton
                      className="btn_cyan_style"
                      aria-label="delete"
                      sx={{ p: 1 }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List
          sx={{ width: "100%", padding: "0px" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {rows.map((row) => (
            <div
              style={{
                borderBottom: "3px solid #00aeef",
                paddingBottom: "1rem",
              }}
            >
              <ListItem>
                <ListItemIcon style={{ fontWeight: "bold" }}>
                  # {row.id}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {row.name}
                      <IconButton
                        className="btn_cyan_style"
                        aria-label="delete"
                        sx={{ p: 1, borderRadius: "10px !important" }}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                />
              </ListItem>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Box
                  component="img"
                  alt="image"
                  className="history_image"
                  src={row.image}
                  sx={{ boxShadow: 3, width: "inherit", borderRadius: "10px" }}
                ></Box>
                <Box
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <span>Price</span>
                  <span style={{ fontWeight: "bold" }}> {row.price}</span>
                </Box>
                <Box
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <span>Status</span>
                  <span style={{ fontWeight: "bold" }}> {row.status}</span>
                </Box>
              </Collapse>
            </div>
          ))}
        </List>
      )}
    </>
  );
};
