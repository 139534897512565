import axios from "axios";
 export const BaseUrl = 'https://api-dev.autoby24.ch';
//  export const BaseUrl = 'https://f202-2400-1a00-b050-9888-e1fe-8dcf-1ba5-2b70.ngrok-free.app';
// export const BaseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}`;

export default axios.create({
  baseURL:`${process.env.REACT_APP_BACKEND_BASE_URL}`,
});

export const user_register_url = "/user/registration/"
export const user_social_register_url = "/user/social-registration/"
export const login_user_url = "/user/login/"
export const send_verify_code_email_urls = "/user/request-password-reset/"
export const verify_user_otp_email_url = "/user/activate-account/"
export const change_password_url = "user/change_password_otp_email/"

export const userRegisterHttpRequest = axios.create({
  baseURL: BaseUrl + user_register_url,
});

export const userLoginHttpRequest = axios.create({
  baseURL: BaseUrl + login_user_url,
});

export const userDetailHttpRequest = axios.create({
  baseURL: BaseUrl + "user/user_info/",
  headers: {
    Authorization:  `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const createProfileHttpRequest = axios.create({
  baseURL: BaseUrl + "/user/create-update-user-profile/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const createVehicleHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/vehicle/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const createLocalAdsHttpRequest = axios.create({
  baseURL: BaseUrl + "/ads/create/local/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const importCSVFileHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/import-vehicle-data/",
});

export const importExcelFileHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/export-vehicle-data/",
});

export const packageSubscriptionHttpRequest = axios.create({
  baseURL: BaseUrl + "/package/subscription",
});

export const localAdvertisementSubscription = axios.create({
  baseURL: BaseUrl + "/package/subscription",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const activateInactiveVehicleHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/activate_inactive_vehicle",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const updateVehicleHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/vehicle/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const updateProfileHttpRequest = axios.create({
  baseURL: BaseUrl + "user/update_profile/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const changePasswordHttpRequest = axios.create({
  baseURL: BaseUrl + "/user/change-password/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const resetPasswordHttpRequest = axios.create({
  baseURL: BaseUrl + "/user/reset-password/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const getAllPackagesHttpRequest = axios.create({
  baseURL: BaseUrl + "/package/all/",
});

export const createCustomizePackage = axios.create({
  baseURL: BaseUrl + "/package/all",
});

export const downloadCsvHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/vehicle",
});

export const getPackagesListHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/vehicle/",
});

export const getListOfBrandHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/brand",
});

export const getListOfCountryHttpRequest = axios.create({
  baseURL: BaseUrl + "/configuration/countries",
});

export const getListOfBrandModelHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/brand-model",
});

export const deletePackagesListHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/vehicle/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const deleteVehicleImageHttpRequest = axios.create({
  baseURL: BaseUrl + "/vehicle/image",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});