import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getRegisterToken } from '../../Redux/Action/Register';
import { VerifyEmailOtp } from "../../Adapters/Auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
function VerifyEmail() {
  const [registerToken, setRegisterToken] = useState("");
  const navigate = useNavigate();
  const [serchparams, setSerchParams] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
    setRegisterToken(serchparams.get("token"));
    handleUserVerify();
  }, []);
  let tokenvalue = serchparams.get("token");
  const handleUserVerify = () => {
    VerifyEmailOtp(tokenvalue, (sucess) => {
      if (sucess) {
        let toast_id = toast.loading('Verifying');
        localStorage.setItem("token", sucess?.data?.data?.token?.access_token);
        localStorage.setItem("user_type", sucess?.data?.data?.user_type);
        localStorage.setItem("refresh_token",sucess?.data?.data?.token?.refresh_token);
        localStorage.setItem("user", JSON.stringify(sucess?.data?.data));
        localStorage.setItem("userId", sucess?.data?.data?.id);
        localStorage.setItem("userEmail",sucess?.data?.data?.email);
        toast.update(toast_id, {
          render: sucess?.message,
          type: 'success',
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        })

        navigate("/user_panel");
      }
    }, (err) => {
      let toast_id1 = toast.loading('Verification Faild');
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      toast.update(
        toast_id1,
        {
          render: err,
          type: 'error',
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        }
      )
      navigate("/");
    })
  }
  return (
    <>
    </>
  )
}

export default VerifyEmail



