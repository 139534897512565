import React from "react";
import LoginCardBlock from "../LoginCardBlock";
import firebase, { GoogleAuthprovider } from "../../../firebaseConf";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SocialRegisterNewUser } from "../../../Adapters/Auth";
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../Redux/Action/loginaction";

const LoginWithGoogle = ({ onVerify, children, is_agreed_terms_condition }, props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoogleLogin = () => {
    firebase
      .auth()
      .signInWithPopup(GoogleAuthprovider)
      .then(function (result) {
        let user = result?.additionalUserInfo?.profile;
        let user_data = {
          user_type: localStorage.getItem("user_type"),
          email: user.email,
          social_platform: "gmail",
          social_id: user.id,
          social_account: true
        };
        // onVerify(user_data);
        let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
        SocialRegisterNewUser(user_data, (sucess) => {
          if (sucess) {
            toast.update(toast_id, {
              render: intl.formatMessage({ id: "LoggedIn-Successful" }),
              type: 'success',
              isLoading: false,
              closeOnClick: true,
              autoClose: 5000,
              pauseOnHover: true,
            })
            localStorage.setItem("user_type", sucess?.data?.data?.user_type);
            localStorage.setItem("token", sucess?.data?.data?.token?.access_token);
            localStorage.setItem("refresh_token", sucess?.data?.data?.token?.access_token);
            localStorage.setItem("user", JSON.stringify(sucess?.data?.data));
            localStorage.setItem("userId", sucess?.data?.data?.id);
            dispatch({
              type: ActionTypes.USER_LOGIN_SUCCESS,
              payload: JSON.stringify(sucess?.data?.data),
            });

            navigate("/edit_profile");
          }
        }, (message) => {
          if (message) {
            toast.update(toast_id, {
              render: message,
              type: 'error',
              isLoading: false,
              closeOnClick: true,
              autoClose: 5000,
              pauseOnHover: true,
            })
          }
        })
      })
      .catch(function (error) { });
  };

  return (
    <>
      {/*  GoogleLogin implemented just commented  due to absence of clientId */}
      <div className="relative">
        <LoginCardBlock
          onClick={() => {
            if (!is_agreed_terms_condition) {
              toast.error('Please agree terms & condition')
            }
            else {
              handleGoogleLogin();
            }
          }}
        >
          {children}
        </LoginCardBlock>
      </div>
    </>
  );
};

LoginWithGoogle.defaultProps = {
  onVerify: () => { },
};

export default LoginWithGoogle;
