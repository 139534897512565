import React, { useState } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "../AdvertisementHistory/AdvertisementHistory.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.css";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function MerchantHistoryDetails(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="AdvertisementHistory"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          Garage Details
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 4 : 1, maxWidth: "100%" }}>
          <Box
            id="historyBox"
            sx={{
              boxShadow: 3,
              p: 6,
              borderRadius: 3,
              background: "var(--auto-text-white)",
            }}
          >
            <BoxHead></BoxHead>
            <BoxBody></BoxBody>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default MerchantHistoryDetails;

const BoxHead = () => {
  const btn_action_object = [
    {
      id: 1,
      name: "CSV",
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 2,
      name: "Excel",
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 3,
      name: "Advertisement Values",
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 4,
      name: "Import CSV",
      icon: <CloudDownloadIcon></CloudDownloadIcon>,
      link: "",
    },
    {
      id: 5,
      name: "Import Excel",
      icon: <CloudDownloadIcon></CloudDownloadIcon>,
      link: "",
    },
    {
      id: 6,
      name: "Download History",
      icon: "",
      link: "",
    },
    {
      id: 7,
      name: "Add Merchant",
      icon: "",
      link: "",
    },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box id="BoxHead">
      <form action="" className="responsiveBoxHead form_history_search">
        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">From Date:</div>
          <HistoryDate></HistoryDate>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">To Date:</div>
          <HistoryDate></HistoryDate>
        </Box>
        <Box className="form_group" sx={{ justifyContent: "flex-end" }}>
          <Button
            className="btn_cyan_style"
            sx={{ py: 0, px: 3, width: matches ? "auto" : "100%" }}
          >
            SUBMIT
          </Button>
        </Box>
      </form>
      <Box className="btn_download_wrapper" sx={{ my: 3 }}>
        {btn_action_object.map((v) => (
          <Button
            key={v.id}
            className="btn_cyan_style"
            sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
          >
            {v.icon}
            {v.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const HistoryDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      className="datePicker"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

function createData(
  id,
  title,
  plz,
  land,
  image,
  parking_type,
  active_date,
  expire_date,
  status,
  action
) {
  return {
    id,
    title,
    plz,
    land,
    image,
    parking_type,
    active_date,
    expire_date,
    status,
    action,
  };
}

const rows = [
  createData(
    1,
    "Garage title",
    "AAA",
    "Munich",
    require("../../static/img/1623152459_merchant.png"),
    "Type 1",
    "10-02-2022",
    "20-02-2022",
    "Active",
    ""
  ),

  createData(
    2,
    "Garage title",
    "AAA",
    "Munich",
    require("../../static/img/1623152459_merchant.png"),
    "Type 2",
    "10-02-2022",
    "20-02-2022",
    "Active",
    ""
  ),
];

const BoxBody = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {matches ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, mb: rows.length == 0 && 30 }}
            aria-label="history_table"
            className="history_table"
          >
            <TableHead>
              <TableRow className="one_line_text">
                <TableCell align="center" sx={{ pl: 0 }}>
                  TITLE
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  PLZ
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  LAND
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  IMAGE
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  PARKING TYPE
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  ACTIVE DATE
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  EXPIRE DATE
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  STATUS
                </TableCell>
                <TableCell align="center" sx={{ pl: 0 }}>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.title}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.plz}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.land}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    <Box
                      component="img"
                      alt="image"
                      className="history_image"
                      src={row.image}
                      sx={{ boxShadow: 3 }}
                    ></Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.parking_type}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.active_date}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.expire_date}
                  </TableCell>
                  <TableCell
                    component="td"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      color: "var(--auto-text-success)",
                    }}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell align="center" component="td">
                    <IconButton
                      className="btn_cyan_style"
                      aria-label="delete"
                      sx={{ p: 1 }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List
          sx={{ width: "100%", padding: "0px" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {rows.map((row) => (
            <div
              style={{
                borderBottom: "3px solid #00aeef",
                paddingBottom: "1rem",
              }}
            >
              <ListItem>
                <ListItemIcon
                  style={{ fontWeight: "bold", marginRight: "1rem" }}
                >
                  {row.title}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {row.status}
                      <IconButton
                        className="btn_cyan_style"
                        aria-label="delete"
                        sx={{ p: 1, borderRadius: "10px !important" }}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                />
              </ListItem>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Box
                  component="img"
                  alt="image"
                  className="history_image"
                  src={row.image}
                  sx={{ boxShadow: 3, width: "inherit", borderRadius: "10px" }}
                ></Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    PLZ
                  </span>
                  <span>{row.plz}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Land
                  </span>
                  <span>{row.land}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Parking type
                  </span>
                  <span>{row.parking_type}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Active date
                  </span>
                  <span>{row.active_date}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Expire date
                  </span>
                  <span>{row.expire_date}</span>
                </Box>
              </Collapse>
            </div>
          ))}
        </List>
      )}
    </>
  );
};
