import React, { useState } from "react";
import "./RentGarage.scss";
import { Box, Grid, Paper, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CountryDropdown from "country-dropdown-with-flags-for-react";
import "../../static/css/forms_style/forms_style.css";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function RentGarage(props) {
  return (
    <div id="RentGarage">
      <Section1></Section1>
      <Section2></Section2>
    </div>
  );
}

const Section1 = () => {
  // ........................input forms value for db request....................
  // for country
  const [countryState, setCountryState] = useState("Bangladesh (বাংলাদেশ)"); // default bd
  //console.log(countryState)

  // for state
  const [locationState, setLocationState] = useState("2"); // default 2
  //console.log(locationState)

  // for postal
  const [postalState, setPostalState] = useState("2"); // default 2
  //console.log(postalState)

  // for radius
  const [radiusState, setRadiusState] = useState("2"); // default 2
  //console.log(radiusState)

  // for park type
  const [parkTypeState, setParkTypeState] = useState("2"); // default 2
  //console.log(parkTypeState)

  // for min price
  const [minPriceState, setMinPriceState] = useState("2"); // default 2
  //console.log(parkTypeState)

  // for max price
  const [maxPriceState, setMaxPriceState] = useState("2"); // default 2
  //console.log(parkTypeState)

  // ................. form object for ajax data......

  const form_object_data = {
    country: countryState,
    state: locationState,
    postalState: postalState,
    radius: radiusState,
    park_type: parkTypeState,
    min_price: minPriceState,
    max_price: maxPriceState,
  };

  // form action button
  const formActionButton = () => {
    console.log(form_object_data);
  };

  // ........................input forms value for db request....................End

  const matches = useMediaQuery("(min-width:600px)");
  return (
    <section id="section1">
      <div className="section_wrapper">
        <div className="Rent_Garage_header">
          <h3 className="cyan_style" style={{ width: matches ? "50%" : "70%" }}>
            Rent Garage
          </h3>
        </div>
        <div className="content_wrapper">
          <h2 className="content_title title_line">Where ?</h2>
          <Box className="box_content" sx={{ mt: 4 }}>
            <form action="" className="form_search">
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
              >
                <Grid item xs={12} md={8}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      {/* <select className="select_control" defaultValue="s">
                        <option value="s" disabled>
                          Country
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select> */}

                      <CountryDropdown
                        style={{ padding: "0px" }}
                        className="form_control"
                        preferredCountries={["bd", "in"]}
                        value={countryState}
                        handleChange={(e) => setCountryState(e.target.value)}
                      ></CountryDropdown>

                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      <select
                        className="select_control"
                        onChange={(event) =>
                          setLocationState(event.target.value)
                        }
                        defaultValue="s"
                      >
                        <option value="s" disabled>
                          State
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      <select
                        className="select_control"
                        onChange={(e) => setPostalState(e.target.value)}
                        defaultValue="s"
                      >
                        <option value="s" disabled>
                          Postal Code
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      <select
                        className="select_control"
                        onChange={(e) => setRadiusState(e.target.value)}
                        defaultValue="s"
                      >
                        <option value="s" disabled>
                          Radius
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </form>
          </Box>

          <Box sx={{ mt: 4 }}>
            <h2 className="content_title title_line">WHAT ?</h2>

            <form action="" className="form_search">
              <Grid
                sx={{ mt: 1 }}
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
              >
                <Grid item xs={12}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      <select
                        className="select_control"
                        onChange={(e) => setParkTypeState(e.target.value)}
                        defaultValue="s"
                      >
                        <option value="s" disabled>
                          Park Type
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      <select
                        className="select_control"
                        onChange={(e) => setMinPriceState(e.target.value)}
                        defaultValue="s"
                      >
                        <option value="s" disabled>
                          Min Price
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={6}>
                  <Item
                    sx={{
                      boxShadow: 0,
                      p: 0,
                      borderRadius: "var(--radius-pill)",
                    }}
                  >
                    <Box className="form_group">
                      <select
                        className="select_control"
                        onChange={(e) => setMaxPriceState(e.target.value)}
                        defaultValue="s"
                      >
                        <option value="s" disabled>
                          Max Price
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                    </Box>
                  </Item>
                </Grid>
              </Grid>

              <Box sx={{ mt: 4 }}>
                <Button
                  onClick={formActionButton}
                  className="section_title_cyan"
                  sx={{ width: "100%", p: 0 }}
                >
                  SEARCH
                </Button>
              </Box>
            </form>
          </Box>
        </div>
      </div>
    </section>
  );
};

const Section2 = () => {
  return (
    <section id="section2">
      <Box sx={{ mt: 2 }}>
        <h3 className="title_line section_title d_inline_block">
          What can be parked in the parking lot
        </h3>

        <Box sx={{ mb: 3 }}>
          <p>
            The rental agreement for the parking lot specifies what canbe parked
            on it. It is better to write down exceptions immediately.
          </p>
        </Box>

        <Box sx={{ mb: 10 }}>
          <p>
            As a rule, a contract is concluded for a parking space for parking
            vehicles. With vehicles, both trailers and motorcycles are meant. If
            you park your bike or even a stroller, you can also use th parking
            lot as a parking space. If the landlord takes it exactly, these
            things should not be left in the parking lot. In order to be
            absolutely sure that the landl,ord doesn't get in your way, you
            should record a change of use in writing in the rental agreement.
          </p>
        </Box>
      </Box>
    </section>
  );
};
