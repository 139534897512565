import React, { useState } from "react";
import "./BuyBikeComponents.scss";
import { Box, Grid, Paper, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import AdvanceSearch from "../../Components/AdvanceSearch/AdvanceSearch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ProductMaterialCard1 } from "../../Components/ProductMaterialCard/ProductMaterialCard";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { AdvanceSearchDrawer } from "../../Components/AdvanceSearch/AdvanceSearch";
import "../../static/css/forms_style/forms_style.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textField: {
    "& [class*=MuiInputBase-root-MuiInput-root]": {
      border: "none",
      display: "none",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function BuyBikeComponents(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div id="BuyBikeComponents">
      <div className="BuyBike_header">
        <h3 className="cyan_style" style={{ width: matches ? "50%" : "70%" }}>
          Buy Bike Components
        </h3>
      </div>
      <div className="BuyBike_body">
        <Section1></Section1>
        <Section2></Section2>
      </div>
    </div>
  );
}

export default BuyBikeComponents;

function Section1() {
  // sorting
  const [searchingState, setSearchingState] = React.useState("2"); // default 2
  const [sorting, setSorting] = React.useState("2"); // default 2
  const [priceFilter, setPriceFilter] = React.useState("2"); // default 2

  //.................  Search form .......................
  const search_form_object = {
    searchingFilter: searchingState,
    sorting: sorting,
    priceFilter: priceFilter,
  };

  //........ buttion action for ajax...........
  const btnSearchFilter = () => {
    console.log(search_form_object); //
  };
  //.................  Search form ....................... End

  // for advance search modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <section className="section1">
      <Box className="box_content">
        <form action="" className="form_search">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select
                    className="select_control"
                    onChange={(event) => setSearchingState(event.target.value)}
                    defaultValue="s"
                  >
                    <option value="s" disabled>
                      Search filter
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select
                    className="select_control"
                    onChange={(event) => setSorting(event.target.value)}
                    defaultValue="s"
                  >
                    <option value="s" disabled>
                      Sorting
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select
                    className="select_control"
                    onChange={(event) => setPriceFilter(event.target.value)}
                    defaultValue="s"
                  >
                    <option value="s" disabled>
                      Price Filter
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sx={{ mt: 8, mb: 4 }}>
              <Item sx={{ boxShadow: 0, p: 0, background: "transparent" }}>
                <Box>
                  <Button
                    className="cyan_style"
                    onClick={btnSearchFilter}
                    sx={{ width: "50%", fontSize: 23 }}
                  >
                    SEARCH
                  </Button>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Item
                sx={{
                  boxShadow: 0,
                  p: 0,
                  background: "transparent",
                  position: "relative",
                }}
              >
                <Box>
                  <Button
                    onClick={toggleDrawer("left", true)}
                    className="btn_advance_search title_line"
                  >
                    Advance Search <ArrowForwardIosIcon></ArrowForwardIosIcon>
                  </Button>

                  <AdvanceSearchDrawer
                    presentationClickHandlle={toggleDrawer("left", false)}
                    presentationKeyDown={toggleDrawer("left", false)}
                    drawerOnClose={toggleDrawer("left", false)}
                    openState={state["left"]}
                  ></AdvanceSearchDrawer>

                  <AdvanceSearch
                    open={open}
                    handleClose={handleClose}
                  ></AdvanceSearch>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </form>
      </Box>
    </section>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Section2() {
  const cardObject = [
    {
      id: 1,
      img: require("../../static/img/ceat-car-two-wheeler-tyre-500x500.jpg"),
      price: "170.00 CHF",
      title: "GT TYPE",
      link: "#",
    },
    {
      id: 2,
      img: require("../../static/img/s-l300.jpg"),
      price: "170.00 CHF",
      title: "CD HEADLIGHT",
      link: "#",
    },
    {
      id: 3,
      img: require("../../static/img/71P1GAww78L._SY355_.jpg"),
      price: "170.00 CHF",
      title: "HEADLIGHTS",
      link: "#",
    },
    {
      id: 4,
      img: require("../../static/img/1585663788-ascher-usb-1585663778.jpg"),
      price: "170.00 CHF",
      title: "HEADLIGHTS",
      link: "#",
    },
    {
      id: 5,
      img: require("../../static/img/ceat-car-two-wheeler-tyre-500x500.jpg"),
      price: "170.00 CHF",
      title: "GT TYRE",
      link: "#",
    },
    {
      id: 6,
      img: require("../../static/img/s-l300.jpg"),
      price: "170.00 CHF",
      title: "CD HEADLIGHT",
      link: "#",
    },
    {
      id: 7,
      img: require("../../static/img/71P1GAww78L._SY355_.jpg"),
      price: "170.00 CHF",
      title: "HEADLIGHT",
      link: "#",
    },
    {
      id: 8,
      img: require("../../static/img/1585663788-ascher-usb-1585663778.jpg"),
      price: "170.00 CHF",
      title: "HEADLIGHTS",
      link: "#",
    },
    {
      id: 9,
      img: require("../../static/img/ceat-car-two-wheeler-tyre-500x500.jpg"),
      price: "170.00 CHF",
      title: "GT TYRE",
      link: "#",
    },
  ];

  const classes = useStyles();

  return (
    <section id="section2">
      <Box>
        <h2
          className="title_line section_title"
          style={{ display: "inline-block" }}
        >
          Find Your Bike Components
        </h2>
        <Box sx={{ px: 3 }}>
          <SearchComponentBox></SearchComponentBox>
        </Box>
        <Box>
          <ProductMaterialCard1 cardObject={cardObject}></ProductMaterialCard1>
        </Box>
      </Box>
    </section>
  );
}

const SearchComponentBox = () => {
  const classes = useStyles();
  const Tires = [
    { label: "Front Tires" },
    { label: "Front1" },
    { label: "Back2" },
    { label: "Front3" },
    { label: "Back4" },
    { label: "Front5" },
    { label: "Back6" },
    { label: "Front7" },
    { label: "Back8" },
    { label: "Front9" },
    { label: "Back10" },
  ];

  const Headlights = [
    { label: "Fron1" },
    { label: "Front Tires2" },
    { label: "Back Tires3" },
    { label: "Front Tires4" },
    { label: "Back Tires5" },
    { label: "Front Tires6" },
    { label: "Back Tires7" },
    { label: "Front Tires8" },
    { label: "Back Tires9" },
    { label: "Front Tires10" },
    { label: "Back Tires11" },
  ];

  const BlinkerLights = [
    { label: "All" },
    { label: "Front Tires2" },
    { label: "Back Tires3" },
    { label: "Front Tires4" },
    { label: "Back Tires5" },
    { label: "Front Tires6" },
    { label: "Back Tires7" },
    { label: "Front Tires8" },
    { label: "Back Tires9" },
    { label: "Front Tires10" },
    { label: "Back Tires11" },
  ];

  const SideMirror = [
    { label: "All" },
    { label: "Front Tires1" },
    { label: "Back Tires2" },
    { label: "Front Tires3" },
    { label: "Back Tires4" },
    { label: "Front Tires5" },
    { label: "Back Tires6" },
    { label: "Front Tires7" },
    { label: "Back Tires8" },
    { label: "Front Tires9" },
    { label: "Back Tires10" },
  ];

  // ................form input values............

  const [tiresState, setTiresState] = useState(Tires[0].label); // default
  const [headLightsState, setHeadLightsState] = useState(Headlights[0].label); // default
  const [blinkerLightsState, setBlinkerLights] = useState(
    BlinkerLights[0].label
  ); // default
  const [sideMirrorState, setSideMirrorState] = useState(SideMirror[0].label); // default

  // ................form input values............End

  // ..................form action for ajax ..........
  const searchFormAction = {
    tires: tiresState,
    head_light: headLightsState,
    blinker_light: blinkerLightsState,
    side_mirror: sideMirrorState,
  };

  console.log(searchFormAction);
  // ..................form action for ajax ..........End

  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          className="form_group_wrapper"
          xs={6}
          md={5}
          sx={{ marginBottom: "20px" }}
        >
          <label htmlFor="">Tires:</label>
          <Item className="form_mui_group" sx={{ boxShadow: "none", pl: 0 }}>
            <Autocomplete
              value={tiresState}
              onChange={(event, newValue) => {
                setTiresState(newValue.label);
              }}
              className="form_mui_group_control"
              defaultValue={Tires[0].label}
              options={Tires}
              renderInput={(params, id) => (
                <TextField
                  data-id={id}
                  className={classes.textField}
                  sx={{
                    border: "1px solid var(--auto-text-47)",
                    borderRadius: "var(--radius-pill)",
                  }}
                  {...params}
                />
              )}
            />
          </Item>
        </Grid>

        <Grid
          item
          className="form_group_wrapper"
          xs={6}
          md={5}
          sx={{ marginBottom: "20px" }}
        >
          <label htmlFor="">Headlights:</label>
          <Item className="form_mui_group" sx={{ boxShadow: "none", pl: 0 }}>
            <Autocomplete
              value={headLightsState}
              onChange={(event, newValue) => {
                setHeadLightsState(newValue.label);
              }}
              className="form_mui_group_control"
              defaultValue={Headlights[0].label}
              options={Headlights}
              renderInput={(params) => (
                <TextField
                  className={classes.textField}
                  sx={{
                    border: "1px solid var(--auto-text-47)",
                    borderRadius: "var(--radius-pill)",
                  }}
                  {...params}
                />
              )}
            />
          </Item>
        </Grid>

        <Grid
          item
          className="form_group_wrapper"
          xs={6}
          md={5}
          sx={{ marginBottom: "20px" }}
        >
          <label htmlFor="">Blinker Lights:</label>
          <Item className="form_mui_group" sx={{ boxShadow: "none", pl: 0 }}>
            <Autocomplete
              value={blinkerLightsState}
              onChange={(event, newValue) => {
                setBlinkerLights(newValue.label);
              }}
              className="form_mui_group_control"
              defaultValue={BlinkerLights[0].label}
              options={BlinkerLights}
              renderInput={(params) => (
                <TextField
                  className={classes.textField}
                  sx={{
                    border: "1px solid var(--auto-text-47)",
                    borderRadius: "var(--radius-pill)",
                  }}
                  {...params}
                />
              )}
            />
          </Item>
        </Grid>

        <Grid
          item
          className="form_group_wrapper"
          xs={6}
          md={5}
          sx={{ marginBottom: "20px" }}
        >
          <label htmlFor="">Side Mirror:</label>
          <Item className="form_mui_group" sx={{ boxShadow: "none", pl: 0 }}>
            <Autocomplete
              value={sideMirrorState}
              onChange={(event, newValue) => {
                setSideMirrorState(newValue.label);
              }}
              className="form_mui_group_control"
              defaultValue={SideMirror[0].label}
              options={SideMirror}
              renderInput={(params) => (
                <TextField
                  className={classes.textField}
                  sx={{
                    border: "1px solid var(--auto-text-47)",
                    borderRadius: "var(--radius-pill)",
                  }}
                  {...params}
                />
              )}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
